import React, { useState } from "react";

export const Label = ({
  children,
  required,
}: {
  children: React.ReactNode;
  required?: boolean;
}) => {
  return (
    <label className="mb-2.5 block text-black ">
      {children}{" "}
      {required ? required && <span className="text-meta-1">*</span> : null}
    </label>
  );
};

interface InputProps {
  type?: string;
  value?: string | number | any;
  name?: string;
  onChange?: (name: string, value: string) => void;
  placeholder?: string;
  isError?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  min?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const Input = ({
  type = "text",
  value = "",
  name = "",
  onChange,
  placeholder = "",
  isError = false,
  disabled = false,
  errorMessage = "",
  min,
  onKeyDown,
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const formatDate = (date: string) => {
    return new Date(date).toISOString().split("T")[0];
  };

  return (
    <div className="w-full">
      <div className="relative w-full">
        <input
          type={isPasswordVisible ? "text" : type}
          placeholder={placeholder}
          value={type === "date" ? formatDate(value) : value}
          name={name}
          onChange={
            onChange
              ? (event) => {
                  onChange(event.target.name, event.target.value);
                }
              : undefined
          }
          onKeyDown={onKeyDown}
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter           disabled:text-gray-400 disabled:font-light"
          disabled={disabled}
          min={min}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
          >
            {isPasswordVisible ? "🙈" : "👁️"}
          </button>
        )}
      </div>
      {isError && (
        <span className="text-red-700">
          {errorMessage ? errorMessage : "Required Field."}
        </span>
      )}
    </div>
  );
};

export const Switcher = ({
  for: toggleId,
  size = "normal",
  onChange,
  isActive,
  disabled = false,
  togglevalue,
}) => {
  const [enabled, setEnabled] = useState(() => {
    return isActive !== undefined && isActive !== null ? isActive : false;
  });

  React.useEffect(() => {
    if (togglevalue !== undefined && togglevalue !== null) {
      setEnabled(togglevalue);
    }
  }, [togglevalue]);

  const handleToggleChange = () => {
    const newEnabled = !enabled;
    setEnabled(newEnabled);
    if (onChange) {
      onChange(newEnabled, toggleId);
    }
  };

  return (
    <label
      htmlFor={`toggle_${toggleId}`}
      className="flex cursor-pointer select-none items-center justify-center w-fit"
    >
      <div className="relative">
        <input
          name={toggleId}
          type="checkbox"
          id={`toggle_${toggleId}`}
          className="sr-only"
          onChange={handleToggleChange}
          disabled={disabled}
        />
        <div
          className={`block rounded-full ${
            enabled
              ? " bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 "
              : " bg-gray-200 "
          }  ${size === "small" ? "h-6 w-12" : "h-8 w-14"}`}
        ></div>
        <div
          className={`absolute rounded-full bg-white transition ${
            enabled &&
            (size === "small"
              ? " !translate-x-[145%]  "
              : " !right-1 !translate-x-full  ")
          } ${
            size === "small"
              ? " left-1 top-1 h-4 w-4 "
              : " left-1 top-1 h-6 w-6 "
          }`}
        ></div>
      </div>
    </label>
  );
};
