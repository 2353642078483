import { useState } from "react";
import { DatePicker, message, Select } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect } from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import TagComponent from "shared-components/tag-component";
import { apiRequest } from "(apis)/api-interface/api-handler";
import * as Yup from "yup";
import { initialValues } from "shared-pages/courses/components/initialValues";
import { Input, Label } from "shared-components/ui/CustomForm";
import { ButtonFill } from "shared-components/ui/CustomButtons";
const { RangePicker } = DatePicker;

const CourseInformation = ({
  id,
  getCourseData,
  courseData,
  loading,
  setLoading,
}) => {
  const initialUrl = courseData?.videoPreview || null;
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(initialUrl);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState(
    courseData?.thumbnail_image || null
  );
  const [courseImagePreview, setCourseImagePreview] = useState(
    courseData?.course_image || null
  );
  const [carouselPreviews, setCarouselPreviews] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>(courseData.tags || []);
  const [tagInput, setTagInput] = useState<string>("");
  const [tagError, setTagError] = useState<string | null>(null);
  console.log("Tags: ", tags);
  const validationSchema = Yup.object().shape({
    courseName: Yup.string()
      .required("Course Name is required")
      .min(4, "minimum 4 characters"),
    videoFile: Yup.mixed()
      .nullable()
      .test("fileType", "File must be a video", (value: any) => {
        if (!value) return true;
        return value.type.startsWith("video/mp4");
      })
      .test("fileSize", "File size must be less than 100MB", (value: any) => {
        if (!value) return true;
        return value && value.size <= 100 * 1024 * 1024;
      }),
    pdfFile: Yup.mixed()
      .nullable()
      .test("filepdf", function (value: any) {
        if (value) {
          console.log("value", value);
          const fileType = value && value.type;
          if (fileType !== "application/pdf") {
            return this.createError({
              message: "File must be a PDF",
            });
          }
        }
        return true;
      }),
    RepresentativeLink: Yup.string().url("Invalid URL format"),
    selectedTeacher: Yup.array().required("Teacher is required").min(1),
    selectedDuration: Yup.object().required("Duration is required"),
    desc: Yup.string()
      .required("Description is required")
      .min(50, "Minimum 50 characters")
      .max(500, "Maximum 500 characters"),
    thumbnail_image: Yup.mixed()
      .nullable()
      .test(
        "fileType",
        "File must be an image (jpg, jpeg, png)",
        (value: any) => {
          if (!value) return true;
          if (typeof value === "string") return true;
          return ["image/jpeg", "image/jpg", "image/png"].includes(value.type);
        }
      )
      .test("fileSize", "File size must be less than 5MB", (value: any) => {
        if (!value) return true;
        if (typeof value === "string") return true;
        return value && value.size <= 5 * 1024 * 1024;
      }),
    course_image: Yup.mixed()
      .nullable()
      .test(
        "fileType",
        "File must be an image (jpg, jpeg, png)",
        (value: any) => {
          if (!value) return true;
          if (typeof value === "string") return true;
          return ["image/jpeg", "image/jpg", "image/png"].includes(value.type);
        }
      )
      .test("fileSize", "File size must be less than 5MB", (value: any) => {
        if (!value) return true;
        if (typeof value === "string") return true;
        return value && value.size <= 5 * 1024 * 1024;
      }),
    carouselImages: Yup.array()
      .of(
        Yup.mixed()
          .test(
            "fileType",
            "Files must be images (jpg, jpeg, png)",
            (value: any) => {
              if (!value) return true;
              if (typeof value === "string") return true;
              return ["image/jpeg", "image/jpg", "image/png"].includes(
                value.type
              );
            }
          )
          .test("fileSize", "Each file must be less than 5MB", (value: any) => {
            if (!value) return true;
            if (typeof value === "string") return true;
            return value && value.size <= 5 * 1024 * 1024;
          })
      )
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      try {
        const {
          courseName,
          pdfFile,
          tagDetails,
          desc,
          videoFile,
          RepresentativeLink,
          videoPreview,
          tag,
          courseXplans,
          selectedDuration,
          colorCode,
        } = values;

        const duration = {
          startDate: dayjs(selectedDuration?.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(selectedDuration?.endDate).format("YYYY-MM-DD"),
        };
        const techers = values?.selectedTeacher?.[0]?.value
          ? values?.selectedTeacher?.map((teacher) => teacher.value)
          : values.selectedTeacher;
        const formData = new FormData();
        formData.append("courseName", courseName || "");
        formData.append("teacherId", techers);
        formData.append("videoPreview", videoPreview || "");
        formData.append("videoFile", videoFile || "");
        formData.append("RepresentativeLink", RepresentativeLink || "");
        formData.append("pdfFile", pdfFile || "");
        formData.append("tag", tag || "");
        formData.append("duration", JSON.stringify(duration) || "");
        formData.append("desc", desc || "");
        // formData.append("courseXplans", courseXplans || []);
        // formData.append("colorCode", colorCode || "");
        // formData.append("tagDetails", JSON.stringify(tagDetails) || "");
        formData.append("thumbnail_image", values.thumbnail_image || "");
        formData.append("course_image", values.course_image || "");
        formData.append("tags", JSON.stringify(tags) || "");

        if (values.carouselImages && values.carouselImages.length > 0) {
          const existingUrls: string[] = [];
          const newFiles: File[] = [];

          values.carouselImages.forEach((image) => {
            if (image instanceof File) {
              newFiles.push(image);
            } else if (typeof image === "string") {
              existingUrls.push(image);
            }
          });

          formData.append("carouselImages", JSON.stringify(existingUrls));
          newFiles.forEach((file) => {
            formData.append("carouselImages", file);
          });
        } else {
          formData.append("carouselImages", JSON.stringify([]));
        }

        setLoading(true);
        // let res
        let res = await apiRequest("updateCourse", formData, [id]);
        if (res?.status === 200) {
          setLoading(false);
          message.success(res?.data?.message);
          getCourseData();
          clearSelectedPdfFile();
          clearSelectedVideo();
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    touched,
    errors,
    handleChange,
  } = formik;

  const [teacherData, setTeacherData] = useState([]);
  const getAllTeachers = async () => {
    const res = await apiRequest("getTeachers");
    if (res?.status === 200) {
      const fomrmatedTeacherData = res?.data?.teachers?.map(
        ({ name, _id }) => ({
          value: _id,
          label: name,
        })
      );
      setTeacherData(fomrmatedTeacherData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTeachers();
  }, []);

  useEffect(() => {
    setValues({
      ...courseData,
      selectedDuration: {
        startDate: dayjs(courseData?.duration?.startDate),
        endDate: dayjs(courseData?.duration?.endDate),
      },
      selectedTeacher:
        courseData?.teacherId?.map((teacher) => ({
          value: teacher?._id,
          label: teacher?.name,
        })) || [],
      tagDetails: courseData?.tagDetails || [
        {
          description: "",
          logo: "",
          isActive: true,
          position: "",
        },
      ],
      thumbnail_image: courseData?.thumbnail_image || null,
      course_image: courseData?.course_image || null,
      carouselImages: courseData?.carouselImages || [],
    });
    // setFieldValue("selectedDuration", courseData?.duration)
    setVideoPreviewUrl(courseData?.videoPreview || null);
    setThumbnailPreview(courseData?.thumbnail_image || null);
    setCourseImagePreview(courseData?.course_image || null);
    setCarouselPreviews(courseData?.carouselImages || []);
  }, [courseData, teacherData, setValues]);

  const handleFileChangeVideo = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("videoFile", file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const clearSelectedVideo = () => {
    setFieldValue("videoFile", null);
    setVideoPreviewUrl(null);
    const fileInput = document.getElementById("videoFile") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handlePdfFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      clearSelectedPdfFile();
      setFieldValue("pdfFile", file);
      setUploadedFileName(file.name); // Set the uploaded file name
    }
  };

  const clearSelectedPdfFile = () => {
    setFieldValue("pdfFile", null);
    setFieldValue("courseOutline", null);
    setUploadedFileName("");
    const fileInput = document.getElementById("pdfFile") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const [accessTags, setAccess] = useState([]);

  const GetAccessTags = async () => {
    try {
      const response = await apiRequest("getDistintTags");
      const formatedData = response?.data?.tags?.map((tag) => ({
        value: tag,
        label: tag,
      }));
      setAccess(formatedData);
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    GetAccessTags();
  }, []);

  const HandleInputChange = (name: string, value: string) => {
    setFieldValue(name, value);
  };

  const handleThumbnailChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("thumbnail_image", file);
      const imageUrl = URL.createObjectURL(file);
      setThumbnailPreview(imageUrl);
    }
  };

  const handleCourseImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("course_image", file);
      const imageUrl = URL.createObjectURL(file);
      setCourseImagePreview(imageUrl);
    }
  };

  const handleCarouselImagesChange = (event) => {
    const files = (event.currentTarget.files || []) as FileList;
    const filesArray = Array.from(files) as File[];

    if (filesArray.length > 0) {
      const currentImages = values.carouselImages || [];
      const newImages = [...currentImages];
      filesArray.forEach((file) => {
        newImages.push(file);
      });

      setFieldValue("carouselImages", newImages);

      const newPreviews = newImages.map((image) => {
        if (image instanceof File) {
          return URL.createObjectURL(image);
        }
        return image as string;
      });

      setCarouselPreviews(newPreviews);
    }
  };

  const clearThumbnail = () => {
    setFieldValue("thumbnail_image", null);
    setThumbnailPreview(null);
    const fileInput = document.getElementById(
      "thumbnail_image"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const clearCourseImage = () => {
    setFieldValue("course_image", null);
    setCourseImagePreview(null);
    const fileInput = document.getElementById(
      "course_image"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const removeCarouselImage = (index: number) => {
    const currentImages = values.carouselImages || [];
    const currentPreviews = carouselPreviews;

    const newImages = currentImages.filter((_, i) => i !== index);
    const newPreviews = currentPreviews.filter((_, i) => i !== index);

    setFieldValue("carouselImages", newImages);
    setCarouselPreviews(newPreviews);
  };

  const clearCarouselImages = () => {
    setFieldValue("carouselImages", []);
    setCarouselPreviews([]);
    const fileInput = document.getElementById(
      "carouselImages"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddTag = (
    e: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent
  ) => {
    e.preventDefault();
    const trimmedTag = tagInput.trim();

    if (!trimmedTag) return;

    // Check for duplicates
    if (tags.includes(trimmedTag)) {
      setTagError("Tag already exists");
      return;
    }

    // Add tag
    setTags([...tags, trimmedTag]);
    setTagInput("");
    setTagError(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Add tag on Enter or comma
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddTag(e);
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className="w-full mt-8 shadow-2xl rounded-2xl p-4">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="mx-4"
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:p-2 w-full md:w-1/2">
            <Label>Course Name</Label>
            <Input
              type="text"
              value={values?.courseName}
              name="courseName"
              onChange={HandleInputChange}
              placeholder="Course Name"
              isError={touched?.courseName && !!errors?.courseName}
              disabled={loading}
            />
          </div>

          <div className="md:p-2 w-full md:w-1/2">
            <div className="flex flex-col ">
              <Label>Select Duration</Label>
              <RangePicker
                className="w-full p-[0.8rem] rounded border border-gray-200"
                name="selectedDuration"
                format="YYYY-MM-DD"
                value={[
                  values?.selectedDuration?.startDate,
                  values?.selectedDuration?.endDate,
                ]}
                onChange={(value) => {
                  setFieldValue("selectedDuration", {
                    startDate: value[0],
                    endDate: value[1],
                  });
                }}
              />
            </div>

            {touched?.selectedDuration && errors?.selectedDuration && (
              <div className="text-red-500 text-sm mt-1">
                {errors.selectedDuration as string}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full   ">
            <Label>Course Description</Label>
            <Input
              type="text"
              value={values?.desc}
              name="desc"
              onChange={HandleInputChange}
              placeholder="Course Description"
              isError={touched?.desc && !!errors?.desc}
              disabled={loading}
            />

            {/* <CustomFormField formik={formik} name="desc" type="textarea" label="Course Description" /> */}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="md:p-2 w-full">
            <Label>
              Representative Link <span className="text-red-500">*</span>
            </Label>
            <Input
              type="text"
              value={values?.RepresentativeLink}
              name="RepresentativeLink"
              onChange={HandleInputChange}
              placeholder="Representative Link"
              isError={
                touched?.RepresentativeLink && !!errors?.RepresentativeLink
              }
              disabled={loading}
            />
          </div>
          <div className="md:p-2 w-full">
            <CustomFormField
              name="selectedTeacher"
              formik={formik}
              type="select"
              mode="multiple"
              options={teacherData}
              label="Select Teacher"
            />
          </div>
        </div>

        {/* <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full flex flex-col">
            <CustomFormField
              name="courseXplans"
              formik={formik}
              type="select"
              mode="multiple"
              options={accessTags}
              label="Select a course tag for plans"
            />
          </div>

          <div className="md:p-2 w-full md:w-1/2  ">
            <Label>
              Color Code <span className="text-red-500">*</span>
            </Label>
            <input
              type="color"
              id="colorCode"
              name="colorCode"
              value={values?.colorCode}
              onChange={handleChange}
              className="w-full !p-6 border border-gray-200 rounded"
            />
            {touched?.colorCode && errors?.colorCode && (
              <div className="text-red-500 text-sm mt-1">
                {errors.colorCode as string}
              </div>
            )}
          </div>
        </div> */}

        <div className="w-full p-2">
          <div className="flex flex-col">
            <Label>Course Outline</Label>
            <div className="flex flex-row flex-wrap items-center  bg-gray-50 ">
              <div className="flex w-1/2 flex-col items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                  />
                </svg>
                <button
                  className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                  onClick={(event) => {
                    event.preventDefault();

                    document.getElementById("pdfFile").click();
                  }}
                >
                  Upload PDF
                </button>
                <p>File accepted: PDF</p>
                {values?.courseOutline && (
                  <button
                    className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 my-0  px-4 rounded border-rose-500"
                    onClick={(e) => {
                      e.preventDefault();
                      clearSelectedPdfFile();
                    }}
                  >
                    Remove PDF Preview
                  </button>
                )}
                <input
                  type="file"
                  id="pdfFile"
                  name="pdfFile"
                  accept="application/pdf"
                  className="hidden"
                  onChange={handlePdfFileChange}
                />
              </div>
              {values?.courseOutline ? (
                <>
                  <div className="flex flex-col items-center gap-2">
                    <iframe src={values?.courseOutline}></iframe>
                  </div>
                </>
              ) : (
                <>
                  {uploadedFileName && (
                    <>
                      <div className="w-1/2 flex flex-col justify-center items-center">
                        <p className="my-0">{uploadedFileName}</p>
                        <button
                          className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                          onClick={(e) => {
                            e.preventDefault();
                            clearSelectedPdfFile();
                          }}
                        >
                          Remove PDF
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {touched?.pdfFile && errors?.pdfFile && (
              <div className="text-red-700">{errors?.pdfFile as string}</div>
            )}
          </div>
        </div>

        <div className="p-2">
          <div className="relative">
            <div className="flex flex-col">
              <h6 className="text-md font-bold text-gray-900 mt-2.5">
                Upload Course Video
              </h6>
              {videoPreviewUrl && (
                <div className="flex flex-col items-center gap-2 my-4  bg-gray-50 ">
                  <video
                    controls
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "240px",
                      width: "100%",
                    }}
                  >
                    <source src={videoPreviewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <h6 className="text-md font-bold text-gray-900 mt-2.5">
                    Course Preview
                  </h6>
                </div>
              )}
              <div className="flex flex-col flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                <div className="flex flex-col items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  <button
                    className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                    onClick={(event) => {
                      event.preventDefault();
                      document.getElementById("videoFile").click();
                    }}
                  >
                    Upload Video
                  </button>
                  <p className="mb-0">File accepted: MP4 (Max.100MB)</p>
                  <input
                    type="file"
                    id="videoFile"
                    name="videoFile"
                    accept="video/mp4"
                    className="hidden"
                    onChange={handleFileChangeVideo}
                  />
                  {videoPreviewUrl && (
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                      onClick={(e) => {
                        e.preventDefault();
                        clearSelectedVideo();
                      }}
                    >
                      Remove Video
                    </button>
                  )}
                </div>
              </div>
            </div>
            {touched?.videoFile && errors?.videoFile && (
              <div className="text-red-700">{errors?.videoFile as string}</div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="md:p-2 w-full md:w-1/2">
            <div className="flex flex-col">
              <Label>
                Thumbnail Image <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-col flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                {thumbnailPreview ? (
                  <div className="flex flex-col items-center gap-2 w-full">
                    <div className="relative w-[200px] h-[200px] flex items-center justify-center group">
                      <img
                        src={thumbnailPreview}
                        alt="Thumbnail Preview"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-300">
                        <button
                          className="bg-white/80  text-rose-500 rounded-full w-16 h-16 flex items-center justify-center"
                          onClick={(e) => {
                            e.preventDefault();
                            clearThumbnail();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-10 h-10"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <button
                      className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                      onClick={(event) => {
                        event.preventDefault();
                        document.getElementById("thumbnail_image").click();
                      }}
                    >
                      Upload Thumbnail
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  id="thumbnail_image"
                  name="thumbnail_image"
                  accept="image/jpeg, image/jpg, image/png"
                  className="hidden"
                  onChange={handleThumbnailChange}
                />
                <p className="mb-0 text-sm text-gray-500">
                  File accepted: JPG, JPEG, PNG (Max.5MB)
                </p>
              </div>
            </div>
            {touched?.thumbnail_image && errors?.thumbnail_image && (
              <div className="text-red-700">
                {errors?.thumbnail_image as string}
              </div>
            )}
          </div>

          <div className="md:p-2 w-full md:w-1/2">
            <div className="flex flex-col">
              <Label>
                Course Image <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-col flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                {courseImagePreview ? (
                  <div className="flex flex-col items-center gap-2 w-full">
                    <div className="relative w-[200px] h-[200px] flex items-center justify-center group">
                      <img
                        src={courseImagePreview}
                        alt="Course Image Preview"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-300">
                        <button
                          className="bg-white/80  text-rose-500 rounded-full w-12 h-12 flex items-center justify-center"
                          onClick={(e) => {
                            e.preventDefault();
                            clearCourseImage();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-8 h-8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <button
                      className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                      onClick={(event) => {
                        event.preventDefault();
                        document.getElementById("course_image").click();
                      }}
                    >
                      Upload Course Image
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  id="course_image"
                  name="course_image"
                  accept="image/jpeg, image/jpg, image/png"
                  className="hidden"
                  onChange={handleCourseImageChange}
                />
                <p className="mb-0 text-sm text-gray-500">
                  File accepted: JPG, JPEG, PNG (Max.5MB)
                </p>
              </div>
            </div>
            {touched?.course_image && errors?.course_image && (
              <div className="text-red-700">
                {errors?.course_image as string}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="md:p-2 w-full">
            <div className="flex flex-col">
              <Label>
                Carousel Images <span className="text-red-500">*</span>
              </Label>
              <div className="flex flex-col flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                <div className="flex flex-col items-center gap-2 w-full">
                  <div className="flex flex-col items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <button
                      className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                      onClick={(event) => {
                        event.preventDefault();
                        document.getElementById("carouselImages").click();
                      }}
                    >
                      Upload Carousel Images
                    </button>
                  </div>
                  <input
                    type="file"
                    id="carouselImages"
                    name="carouselImages"
                    accept="image/jpeg, image/jpg, image/png"
                    multiple
                    className="hidden"
                    onChange={handleCarouselImagesChange}
                  />
                  <p className="mb-0 text-sm text-gray-500">
                    File accepted: JPG, JPEG, PNG (Max.5MB)
                  </p>
                </div>
                {carouselPreviews?.length > 0 && (
                  <div className="flex flex-col items-center gap-2 w-full">
                    <div className="flex overflow-x-auto space-x-4 py-2 px-2 w-full">
                      {carouselPreviews?.map((preview, index) => (
                        <div
                          key={index}
                          className="flex-shrink-0 relative flex flex-col items-center gap-2"
                        >
                          <div className="relative w-[200px] h-[200px] flex items-center justify-center group">
                            <img
                              src={preview}
                              alt={`Carousel Image ${index + 1}`}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-300">
                              <button
                                className="bg-white/90 text-rose-500 rounded-full w-12 h-12 flex items-center justify-center"
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeCarouselImage(index);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-8 h-8"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500 mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        clearCarouselImages();
                      }}
                    >
                      Remove All Carousel Images
                    </button>
                  </div>
                )}
              </div>
            </div>
            {touched?.carouselImages && errors?.carouselImages && (
              <div className="text-red-700">
                {errors?.carouselImages as string}
              </div>
            )}
          </div>
        </div>

        <div className="w-full p-2">
          <div className="flex flex-col">
            <Label>Course Tags</Label>
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={tagInput}
                  onChange={(e) => {
                    setTagInput(e.target.value);
                    setTagError(null);
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter tag (press Enter to add)"
                  className="flex-grow p-2 border rounded-md focus:ring-primary focus:border-primary"
                />
                <button
                  onClick={handleAddTag}
                  className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary/80"
                >
                  Add
                </button>
              </div>
              {tagError && (
                <p className="text-red-500 text-sm mt-1">{tagError}</p>
              )}
            </div>

            {tags.length > 0 && (
              <div className="mb-4">
                <h3 className="text-md font-medium mb-2">Tags:</h3>
                <div className="flex flex-wrap gap-2 max-h-40 overflow-y-auto border p-2 rounded-md">
                  {tags.map((tag) => (
                    <div
                      key={tag}
                      className="bg-gray-100 px-3 py-1 rounded-full flex items-center space-x-2"
                    >
                      <span>{tag}</span>
                      <button
                        onClick={() => handleRemoveTag(tag)}
                        className="text-red-500 hover:text-red-700"
                        title="Remove tag"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <TagComponent formik={formik} /> */}

        <div className="flex justify-end items-end mx-4 mt-4 ">
          <ButtonFill isLoading={loading} handleClick={handleSubmit}>
            {loading ? <>Saving..</> : <>Save</>}
          </ButtonFill>
        </div>
      </form>
    </div>
  );
};

export default CourseInformation;
