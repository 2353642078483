import React from 'react';

interface CustomFormikErrorProps {
  name?: string;
  touched?: boolean;
  error?: string;
  align?: 'start' | 'center';
}

const CustomFormikError: React.FC<CustomFormikErrorProps> = ({
  name,
  touched,
  error,
  align = 'start',
}) => {
  // console.log("err", error)
  return (
    <>
    {/* && touched  */}
      {name && (
        <div
          className={`w-full ps-0 pe-4 ${align === 'start' ? 'text-start' : 'text-center'} mt-1`}
          style={{
            color: '#d32f2f',
            fontSize: '15px',
          }}
        >
          {error}
        </div>
      )}
    </>
  );
};

export default CustomFormikError;
