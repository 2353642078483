"use client"

import axios from "axios"
import { GetBackendUrlForCategory } from "constants/config"
import { Loader } from "lucide-react"
import { useState, useEffect } from "react"
import { Tooltip, Legend, XAxis, YAxis, CartesianGrid, BarChart, Bar, ResponsiveContainer } from "recharts"
import { backendUrl as rootPath } from "constants/config"

interface InternalData {
    category: string
    department: string
    total_expense: number,
    internal_expense: number,
    external_expense: number,
    misc_expense: number,
    total_revenue: number,
    order_revenue: number,
    course_revenue: number,
    external_revenue: number,
}

interface ExternalData {
    category: string
    department: string
    total_expense: number
}

interface MiscData {
    category: string
    department: string
    total_expense: number
}

interface TimeSeriesData {
    date: string
    [department: string]: number | string
}

const COLORS = [
    "#0088FE", // Blue
    "#00C49F", // Green (removed)
    "#FFBB28", // Yellow (removed)
    "#FF8042", // Orange (removed)
    "#8884d8", // Purple
    "#82ca9d", // Green (removed)
    "#a4de6c", // Green (removed)
    "#d0ed57", // Green (removed)
    "#ffc658", // Yellow (removed)
    "#8dd1e1", // Light Blue
    // "#82ca9d", // Green (removed)
    "#83a6ed", // Light Blue
    "#8884d8", // Purple
    "#ff9896", // Light Red
    "#d62728", // Red
    "#e377c2", // Pink (removed)
    "#7f7f7f", // Gray (removed)
    "#bcbd22", // Olive (removed)
    "#17becf", // Teal (removed)
    "#9467bd", // Purple
]

const CDN_BASE_URL = 'https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Finance';

const RevenueSummaryPage = () => {
    const [internalData, setInternalData] = useState<InternalData[]>([])
    const [externalData, setExternalData] = useState<ExternalData[]>([])
    const [miscData, setMiscData] = useState<MiscData[]>([])

    const [originalInternalData, setOriginalInternalData] = useState<any[]>([])
    const [originalExternalData, setOriginalExternalData] = useState<any[]>([])
    const [originalMiscData, setOriginalMiscData] = useState<any[]>([])
    const [monthlyData, setMonthlyData] = useState<{ [category: string]: TimeSeriesData[] }>({})
    const [yearlyData, setYearlyData] = useState<{ [category: string]: TimeSeriesData[] }>({})
    const [loading, setLoading] = useState(true)
    const [monthlyBreakdownData, setMonthlyBreakdownData] = useState<any>({})
    const [premed_pending_commissions, setPremedPendingCommissions] = useState<number>(0)


    const [premed_commissions, setPremedCommissions] = useState<any[]>([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${rootPath}/api/finance/get-revenue-summary`)
                const backendUrl = `${GetBackendUrlForCategory('PRE-MEDICAL')}/api/revenue/get-revenue-data`;
                const oldOrdersUrl = `${CDN_BASE_URL}/old-orders.json`;
                const reimbursementStatesUrl = `${GetBackendUrlForCategory('PRE-MEDICAL')}/api/v2/latest-commission/stats/commission/new/reimbursement`;
                const com_url = `${GetBackendUrlForCategory('PRE-MEDICAL')}/api/v2/latest-commission/stats/reimbursement-states`

                const [response2, oldDataResponse, reimbursementStatesResponse, com_response] = await Promise.all([
                    axios.get(backendUrl),
                    axios.get(oldOrdersUrl),
                    axios.get(reimbursementStatesUrl),
                    axios.get(com_url)
                ]);

                const commissions = reimbursementStatesResponse.data.data;
                const pending_commissions = com_response.data.data.totalPendingCommissionAmount
                setPremedPendingCommissions(pending_commissions)
                const { Orders: currentOrders, ...summaryData } = response2.data.data;
                const oldOrders = oldDataResponse.data;

                // Aggregate old order data
                const structuredData = [...currentOrders];
                oldOrders.forEach((item) => {
                    const date = item["CreationDate/$date"].split('T')[0];
                    const existingData = structuredData.find(entry => entry.date === date);

                    if (existingData) {
                        existingData.totalRevenue += item.PaidAmount || 0;
                        existingData.totalCouponDiscount += item.DiscountAmount || 0;
                        existingData.totalDiscount += (item.TotalAmount - item.PaidAmount) || 0;
                        existingData.totalOrders += 1;
                    } else {
                        structuredData.push({
                            date,
                            totalRevenue: item.PaidAmount || 0,
                            totalCouponDiscount: item.DiscountAmount || 0,
                            totalDiscount: (item.TotalAmount - item.PaidAmount) || 0,
                            totalOrders: 1
                        });
                    }
                });

                // Calculate aggregate values for old orders
                const oldOrderSummary = oldOrders.reduce((acc, item) => {
                    acc.totalRevenue += item.PaidAmount || 0;
                    acc.totalCouponDiscount += item.DiscountAmount || 0;
                    acc.totalDiscount += (item.TotalAmount - item.PaidAmount) || 0;
                    acc.totalOrders += 1;
                    return acc;
                }, {
                    totalRevenue: 0,
                    totalCouponDiscount: 0,
                    totalDiscount: 0,
                    totalOrders: 0
                });

                const orderData = {
                    Orders: structuredData,
                    CourseOrders: summaryData.CourseOrders,
                    TotalRevenue: summaryData.TotalRevenue + oldOrderSummary.totalRevenue,
                    TotalCouponDiscount: summaryData.TotalCouponDiscount + oldOrderSummary.totalCouponDiscount,
                    TotalDiscount: summaryData.TotalDiscount + oldOrderSummary.totalDiscount,
                    TotalOrders: summaryData.TotalOrders + oldOrderSummary.totalOrders,
                    TotalCourseRevenue: summaryData.TotalCourseRevenue,
                    TotalCourseCouponDiscount: summaryData.TotalCourseCouponDiscount,
                    TotalCourseDiscount: summaryData.TotalCourseDiscount,
                    TotalCourseOrders: summaryData.TotalCourseOrders,
                    OldOrders: oldOrders
                }
                const after_revamp = commissions.newCommissionSystem.dailyStats.map((commission: any) => {
                    const date = commission.date.split('T')[0];
                    return {
                        date: date,
                        expense: commission.totalApprovedAmount
                    }
                })

                const before_revamp = commissions.oldCommissionSystem.dailyStats.map((commission: any) => {
                    const date = commission.date.split('T')[0];
                    return {
                        date: date,
                        expense: commission.totalAmount
                    }
                })
                const p_commissions = [...after_revamp, ...before_revamp]
                setPremedCommissions(p_commissions)
                const data = await response.json()

                if (data.isSuccess) {
                    setOriginalInternalData(data.data.internal)
                    setOriginalExternalData(data.data.external)
                    setOriginalMiscData(data.data.miscellaneous)
                    processInternalData(data.data.internal, data.data.external, data.data.miscellaneous, orderData, data.data.revenue, premed_commissions)
                    processTimeSeriesData(data.data.internal, data.data.external, data.data.miscellaneous, p_commissions)
                    ProcessMonthWiseForCategory(data.data.internal, data.data.external, data.data.miscellaneous, orderData, data.data.revenue, p_commissions)
                }



                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    const processInternalData = (data: any[], externalData: any[], miscData: any[], ordersData: { Orders: any[], CourseOrders: any[], TotalRevenue: number, TotalCourseRevenue: number }, revenueData: any[], commissions: any[]) => {
        const formattedInternalData: InternalData[] = []

        const uniqueCategoriesI = Array.from(new Set(data.map((item) => item.category)))
        const uniqueDepartmentsI = Array.from(new Set(data.map((item) => item.department)))

        //if some category or department is present in excternal or misc but not in internal then add it to internal
        const uniqueCategoriesE = Array.from(new Set(externalData.map((item) => item.category)))
        const uniqueDepartmentsE = Array.from(new Set(externalData.map((item) => item.department)))

        const uniqueCategoriesM = Array.from(new Set(miscData.map((item) => item.category)))
        const uniqueDepartmentsM = Array.from(new Set(miscData.map((item) => item.department)))

        //add Commissions named,
        const manual_to_add = ["Commissions"]

        const uniqueCategories = Array.from(new Set([...uniqueCategoriesI, ...uniqueCategoriesE, ...uniqueCategoriesM]))
        const uniqueDepartments = Array.from(new Set([...uniqueDepartmentsI, ...uniqueDepartmentsE, ...uniqueDepartmentsM, ...manual_to_add]))


        uniqueCategories.forEach((category) => {
            uniqueDepartments.forEach((department) => {
                const total_expense = data
                    .filter((item: any) => item.category === category && item.department === department)
                    .reduce((sum: number, item: any) => sum + item.expense, 0)

                const external_total_expense = externalData
                    .filter((item: any) => item.category === category && item.department === department)
                    .reduce((sum: number, item: any) => sum + item.expense, 0)

                const misc_total_expense = miscData
                    .filter((item: any) => item.category === category && item.department === department)
                    .reduce((sum: number, item: any) => sum + item.expense, 0)

                const external_revenue = revenueData
                    .filter((item: any) => item.category === category)
                    .reduce((sum: number, item: any) => sum + item.revenue, 0)


                let totalRevenue = 0;
                let orderRevenue = 0;
                let courseRevenue = 0;

                if (category === "PRE-MEDICAL") {
                    orderRevenue = ordersData.Orders.reduce((sum: number, item: any) => sum + item.totalRevenue, 0)
                    courseRevenue = ordersData.CourseOrders.reduce((sum: number, item: any) => sum + item.totalRevenue, 0)
                    totalRevenue = orderRevenue + courseRevenue + external_revenue
                }

                if (total_expense > 0 || external_total_expense > 0 || misc_total_expense > 0 || totalRevenue > 0) {
                    formattedInternalData.push({
                        category: category as string,
                        department: department as string,
                        total_expense: total_expense + external_total_expense + misc_total_expense,
                        internal_expense: total_expense,
                        external_expense: external_total_expense,
                        misc_expense: misc_total_expense,
                        order_revenue: orderRevenue,
                        course_revenue: courseRevenue,
                        external_revenue: external_revenue,
                        total_revenue: totalRevenue,
                    })
                }
            })
        })

        setInternalData(formattedInternalData)
    }


    const ProcessMonthWiseForCategory = (data: any[], externalData: any[], miscData: any[], ordersData: { Orders: any[], CourseOrders: any[] }, revenueData: any[], premed_commissions: any[]) => {
        const categoryData: {
            [category: string]: {
                monthly: {
                    [key: string]: {
                        total_expense: number,
                        internal_expense: number,
                        external_expense: number,
                        misc_expense: number,
                        order_revenue: number,
                        course_revenue: number,
                        external_revenue: number,
                        total_revenue: number,
                        commissions_expense: number
                    }
                }
            }
        } = {}

        // Process internal data
        data.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {} }
            }
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    order_revenue: 0,
                    course_revenue: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }

            categoryData[item.category].monthly[monthKey].internal_expense += item.expense
            categoryData[item.category].monthly[monthKey].total_expense += item.expense
        })

        // Process external data
        externalData.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {} }
            }
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    order_revenue: 0,
                    course_revenue: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }

            categoryData[item.category].monthly[monthKey].external_expense += item.expense
            categoryData[item.category].monthly[monthKey].total_expense += item.expense
        })

        // Process misc data
        miscData.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {} }
            }
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    order_revenue: 0,
                    course_revenue: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }

            categoryData[item.category].monthly[monthKey].misc_expense += item.expense
            categoryData[item.category].monthly[monthKey].total_expense += item.expense
        })


        revenueData.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {} }
            }
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    order_revenue: 0,
                    course_revenue: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }
            categoryData[item.category].monthly[monthKey].external_revenue += item.revenue
            categoryData[item.category].monthly[monthKey].total_revenue += item.revenue
        })


        ordersData.Orders.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData["PRE-MEDICAL"]) {
                categoryData["PRE-MEDICAL"] = { monthly: {} }
            }
            if (!categoryData["PRE-MEDICAL"].monthly[monthKey]) {
                categoryData["PRE-MEDICAL"].monthly[monthKey] = {
                    order_revenue: 0,
                    course_revenue: 0,
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }
            categoryData["PRE-MEDICAL"].monthly[monthKey].order_revenue += item.totalRevenue
            categoryData["PRE-MEDICAL"].monthly[monthKey].total_revenue += item.totalRevenue
        })

        ordersData.CourseOrders.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData["PRE-MEDICAL"]) {
                categoryData["PRE-MEDICAL"] = { monthly: {} }
            }
            if (!categoryData["PRE-MEDICAL"].monthly[monthKey]) {
                categoryData["PRE-MEDICAL"].monthly[monthKey] = {
                    order_revenue: 0,
                    course_revenue: 0,
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }
            categoryData["PRE-MEDICAL"].monthly[monthKey].course_revenue += item.totalRevenue
            categoryData["PRE-MEDICAL"].monthly[monthKey].total_revenue += item.totalRevenue
        })

        premed_commissions.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`

            if (!categoryData["PRE-MEDICAL"]) {
                categoryData["PRE-MEDICAL"] = { monthly: {} }
            }

            if (!categoryData["PRE-MEDICAL"].monthly[monthKey]) {
                categoryData["PRE-MEDICAL"].monthly[monthKey] = {
                    total_expense: 0,
                    internal_expense: 0,
                    external_expense: 0,
                    misc_expense: 0,
                    order_revenue: 0,
                    course_revenue: 0,
                    external_revenue: 0,
                    total_revenue: 0,
                    commissions_expense: 0
                }
            }

            categoryData["PRE-MEDICAL"].monthly[monthKey].commissions_expense += item.expense
            categoryData["PRE-MEDICAL"].monthly[monthKey].total_expense += item.expense
        })

        setMonthlyBreakdownData(categoryData)
    }



    const processTimeSeriesData = (data: any[], externalData: any[], miscData: any[], p_commissions: any[]) => {
        const categoryData: { [category: string]: { monthly: {}; yearly: {} } } = {}

        data.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`
            const yearKey = `${date.getFullYear()}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {}, yearly: {} }
            }

            // Monthly data processing
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {}
            }
            if (!categoryData[item.category].monthly[monthKey][item.department]) {
                categoryData[item.category].monthly[monthKey][item.department] = 0
            }
            categoryData[item.category].monthly[monthKey][item.department] += item.expense

            // Yearly data processing
            if (!categoryData[item.category].yearly[yearKey]) {
                categoryData[item.category].yearly[yearKey] = {}
            }
            if (!categoryData[item.category].yearly[yearKey][item.department]) {
                categoryData[item.category].yearly[yearKey][item.department] = 0
            }
            categoryData[item.category].yearly[yearKey][item.department] += item.expense
        })

        externalData.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`
            const yearKey = `${date.getFullYear()}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {}, yearly: {} }
            }

            // Monthly data processing for external data
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {}
            }
            if (!categoryData[item.category].monthly[monthKey][item.department]) {
                categoryData[item.category].monthly[monthKey][item.department] = 0
            }
            categoryData[item.category].monthly[monthKey][item.department] += item.expense

            // Yearly data processing for external data
            if (!categoryData[item.category].yearly[yearKey]) {
                categoryData[item.category].yearly[yearKey] = {}
            }
            categoryData[item.category].yearly[yearKey][item.department] = item.expense
        })

        miscData.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`
            const yearKey = `${date.getFullYear()}`

            if (!categoryData[item.category]) {
                categoryData[item.category] = { monthly: {}, yearly: {} }
            }

            // Monthly data processing for misc data
            if (!categoryData[item.category].monthly[monthKey]) {
                categoryData[item.category].monthly[monthKey] = {}
            }
            if (!categoryData[item.category].monthly[monthKey][item.department]) {
                categoryData[item.category].monthly[monthKey][item.department] = 0
            }
            categoryData[item.category].monthly[monthKey][item.department] += item.expense

            // Yearly data processing for misc data  
            if (!categoryData[item.category].yearly[yearKey]) {
                categoryData[item.category].yearly[yearKey] = {}
            }
            categoryData[item.category].yearly[yearKey][item.department] = item.expense
        })

        p_commissions.forEach((item: any) => {
            const date = new Date(item.date)
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`
            const yearKey = `${date.getFullYear()}`

            if (!categoryData["PRE-MEDICAL"]) {
                categoryData["PRE-MEDICAL"] = { monthly: {}, yearly: {} }
            }

            if (!categoryData["PRE-MEDICAL"].monthly[monthKey]) {
                categoryData["PRE-MEDICAL"].monthly[monthKey] = {}
            }
            if (!categoryData["PRE-MEDICAL"].monthly[monthKey]["COMMISSIONS"]) {
                categoryData["PRE-MEDICAL"].monthly[monthKey]["COMMISSIONS"] = 0
            }
            categoryData["PRE-MEDICAL"].monthly[monthKey]["COMMISSIONS"] += item.expense

            if (!categoryData["PRE-MEDICAL"].yearly[yearKey]) {
                categoryData["PRE-MEDICAL"].yearly[yearKey] = {}
            }
            if (!categoryData["PRE-MEDICAL"].yearly[yearKey]["COMMISSIONS"]) {
                categoryData["PRE-MEDICAL"].yearly[yearKey]["COMMISSIONS"] = 0
            }
            categoryData["PRE-MEDICAL"].yearly[yearKey]["COMMISSIONS"] += item.expense
        })

        const monthlyChartData: { [category: string]: TimeSeriesData[] } = {}
        const yearlyChartData: { [category: string]: TimeSeriesData[] } = {}

        Object.entries(categoryData).forEach(([category, data]) => {
            monthlyChartData[category] = Object.entries(data.monthly)
                .map(([date, depts]) => {
                    const entry: any = { date }
                    Object.entries(depts as Record<string, number>).forEach(([dept, amount]) => {
                        entry[dept] = amount
                    })
                    return entry
                })
                .sort((a, b) => a.date.localeCompare(b.date))

            yearlyChartData[category] = Object.entries(data.yearly)
                .map(([date, depts]) => {
                    const entry: any = { date }
                    Object.entries(depts as Record<string, number>).forEach(([dept, amount]) => {
                        entry[dept] = amount
                    })
                    return entry
                })
                .sort((a, b) => a.date.localeCompare(b.date))
        })

        setMonthlyData(monthlyChartData)
        setYearlyData(yearlyChartData)
    }

    const generateDepartmentColors = (departments: string[]) => {
        return departments.reduce(
            (acc, dept, index) => {
                // Add a specific color for "COMMISSIONS" if it exists
                if (dept === "COMMISSIONS") {
                    acc[dept] = "#FF6B6B"  // A distinct color for commissions
                } else {
                    acc[dept] = COLORS[index % COLORS.length]
                }
                return acc
            },
            {} as { [key: string]: string },
        )
    }

    const [categories, setCategories] = useState<string[]>(["PRE-MEDICAL", "MEDSCHOOL", "PRE-ENGINEERING", "FSC","ALLIED-HEALTH-SCIENCES"])
    const [activeCategory, setActiveCategory] = useState<string | null>("PRE-MEDICAL")


    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Loader className="animate-spin" />
            </div>
        )
    }


    return (
        <div className="min-h-screen p-2">
            <h1 className="text-4xl font-bold mb-8 text-center text-gray-800 shadow-text">Revenue Summary</h1>
            <div className="mb-8 flex justify-center">
                <div className="inline-flex flex-wrap mb-4 rounded-md shadow-sm" role="group">
                    {categories.map((category) => (
                        <button
                            key={category}
                            type="button"
                            onClick={() => setActiveCategory(category)}
                            className={`
                                px-4 py-2 text-sm font-medium 
                                ${activeCategory === category 
                                    ? 'bg-blue-500 text-white' 
                                    : 'bg-white text-gray-900 hover:bg-gray-100'}
                                border border-gray-200 
                                first:rounded-l-lg 
                                last:rounded-r-lg 
                                focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700
                            `}
                        >
                            {category}
                        </button>
                    ))}
                </div>
            </div>
            {/* Time Series Charts */}
            <div className="mt-12">
                {Array.from(new Set(internalData.map((item) => item.category))).map((category) => {
                    const departmentsI = Array.from(
                        new Set(internalData.filter((item) => item.category === category).map((item) => item.department)),
                    )
                    const departmentsE = Array.from(
                        new Set(originalExternalData.filter((item) => item.category === category).map((item) => item.department)),
                    )
                    const departmentsM = Array.from(
                        new Set(originalMiscData.filter((item) => item.category === category).map((item) => item.department)),
                    )

                    // Explicitly add "COMMISSIONS" for PRE-MEDICAL category
                    const departments = category === "PRE-MEDICAL" 
                        ? Array.from(new Set([...departmentsI, ...departmentsE, ...departmentsM, "COMMISSIONS"]))
                        : Array.from(new Set([...departmentsI, ...departmentsE, ...departmentsM]))
                    const departmentColors = generateDepartmentColors(departments)

                    if (category !== activeCategory) {
                        return null
                    }

                    return (
                        <div key={category} className="mb-16 bg-white rounded-lg shadow-lg p-6">
                            <h2 className="text-2xl font-bold mb-6 text-gray-700">{category} - Trends</h2>

                            {/* Monthly Trends */}
                            <div className="mb-12">
                                <h3 className="text-xl font-semibold mb-4 text-gray-600">Monthly Trends</h3>
                                <div className="w-full h-[400px]">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={monthlyData[category] || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} />
                                            {/* <Legend /> */}
                                            {departments.map((dept) => (
                                                <Bar key={dept} dataKey={dept} stackId="expenses" fill={departmentColors[dept]} name={dept} radius={[2, 2, 0, 0]} />
                                            ))}
                                            {/* <Bar dataKey="total_revenue" stackId="revenue" fill="#82ca9d" name="Total Revenue" /> */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                                {/* New Monthly Breakdown Table */}
                                <div className="mt-6 overflow-x-auto">
                                    <table className="min-w-full bg-white">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">Month</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Internal</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">External</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Misc</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Commissions</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Order Revenue</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Course Revenue</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">External Revenue</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Total Expense</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Total Revenue</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">P&L</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {monthlyBreakdownData[category]?.monthly &&
                                                Object.entries(monthlyBreakdownData[category].monthly)
                                                    .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
                                                    .map(([date, data]: [string, any]) => (
                                                        <tr key={date} className="hover:bg-gray-50">
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {date}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-rose-500">
                                                                {data.internal_expense.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-rose-500">
                                                                {data.external_expense.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-rose-500">
                                                                {data.misc_expense.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-rose-500">
                                                                {data.commissions_expense.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-green-500">
                                                                {data.order_revenue.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-green-500">
                                                                {data.course_revenue.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-green-500">
                                                                {data.external_revenue.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-red-500">
                                                                {data.total_expense.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-green-500">
                                                                {data.total_revenue.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "PKR",
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                                                                {data.total_revenue !== 0 ? (
                                                                    data.total_revenue - data.total_expense > 0 ? (
                                                                        <span className="bg-green-500 text-white px-2 py-1 rounded-md">
                                                                            ({((data.total_revenue - data.total_expense) / data.total_revenue * 100).toFixed(1)}%)
                                                                        </span>
                                                                    ) : (
                                                                        <span className="bg-red-500 text-white px-2 py-1 rounded-md">
                                                                            ({((data.total_revenue - data.total_expense) / data.total_revenue * 100).toFixed(1)}%)
                                                                        </span>
                                                                    )
                                                                ) : (
                                                                    <span className="text-gray-500">-</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Yearly Trends */}
                            <div>
                                <h3 className="text-xl font-semibold mb-4 text-gray-600">Yearly Trends</h3>
                                <div className="w-full h-[400px]">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={yearlyData[category] || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} />
                                            {/* <Legend /> */}
                                            {departments.map((dept) => (
                                                <Bar key={dept} dataKey={dept} stackId="a" fill={departmentColors[dept]} name={dept} />
                                            ))}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* Category-wise Tables */}
            <div className="grid grid-cols-1 gap-8">
                {Array.from(new Set(internalData.map((item) => item.category))).map((category, idx) => {
                    const categoryData = internalData
                        .filter((item) => item.category === category)
                        .sort((a, b) => b.total_expense - a.total_expense)

                    // Calculate totals correctly using the processed categoryData
                    const totalInternalExpense = categoryData.reduce((sum, item) => sum + item.internal_expense, 0)
                    const totalExternalExpense = categoryData.reduce((sum, item) => sum + item.external_expense, 0)
                    const totalMiscExpense = categoryData.reduce((sum, item) => sum + item.misc_expense, 0)
                    const totalCategoryExpense = totalInternalExpense + totalExternalExpense + totalMiscExpense

                    const totalExternalRevenue = categoryData.reduce((sum, item) => item.external_revenue, 0)
                    const totalOrderRevenue = categoryData.reduce((sum, item) => item.order_revenue, 0)
                    const totalCourseRevenue = categoryData.reduce((sum, item) => item.course_revenue, 0)
                    const totalCategoryRevenue = totalOrderRevenue + totalCourseRevenue + totalExternalRevenue
                    

                    let pend_commissions = 0
                    let totalCommissionsExpense = 0
                    if (category === "PRE-MEDICAL") {
                        pend_commissions = premed_pending_commissions
                        totalCommissionsExpense = premed_commissions.reduce((sum, item) => sum + item.expense, 0)
                    }
                    
                    if (category !== activeCategory) {
                        return null
                    }

                    return (
                        <div key={idx} className="bg-white p-6 rounded-lg">
                            <h3 className="text-2xl font-bold mb-6 text-gray-700">{category}</h3>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <Card
                                    title="Total Internal"
                                    description="Total internal expense incurred by the company"
                                    data={totalInternalExpense}
                                />
                                <Card
                                    title="Total External"
                                    description="Total external expense incurred by the company"
                                    data={totalExternalExpense}
                                />
                                <Card
                                    title="Total Misc"
                                    description="Total misc expense incurred by the company"
                                    data={totalMiscExpense}
                                />
                                <Card
                                    title="Pending Commissions"
                                    description="Total pending commissions"
                                    data={pend_commissions || 0}
                                />
                                <Card
                                    title="Total Commissions"
                                    description="Total commissions expense incurred by the company"
                                    data={totalCommissionsExpense}
                                />
                                <Card
                                    title="Total External Revenue"
                                    description="Total external revenue incurred by the company"
                                    data={totalExternalRevenue}
                                />
                                <Card
                                    title="Total Order Revenue"
                                    description="Total order revenue incurred by the company"
                                    data={totalOrderRevenue}
                                />
                                <Card
                                    title="Total Course Revenue"
                                    description="Total course revenue incurred by the company"
                                    data={totalCourseRevenue}
                                />
                                <Card
                                    color="bg-gray-300"
                                    title="Total Expense"
                                    description="Total expense incurred by the company"
                                    data={totalCategoryExpense + totalCommissionsExpense}
                                />
                                <Card
                                    color="bg-gray-300"
                                    title="Total Revenue"
                                    description="Total revenue incurred by the company"
                                    data={totalCategoryRevenue}
                                />
                            </div>

                            <div className="overflow-x-auto mt-4">
                                <table className="min-w-full bg-white">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                Department
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                Internal
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                External
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                Misc
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                Amount
                                            </th>

                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">
                                                % of Category
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {categoryData.map((item, index) => (
                                            <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {item.department}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
                                                    {item.internal_expense.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "PKR",
                                                    })}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
                                                    {item.external_expense.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "PKR",
                                                    })}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
                                                    {item.misc_expense.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "PKR",
                                                    })}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
                                                    {item.total_expense.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "PKR",
                                                    })}
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
                                                    {((item.total_expense / totalCategoryExpense) * 100).toFixed(1)}%
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="bg-gray-100 font-semibold">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                {totalInternalExpense.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "PKR",
                                                })}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                {totalExternalExpense.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "PKR",
                                                })}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                {totalMiscExpense.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "PKR",
                                                })}
                                            </td>

                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                {totalCategoryExpense.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "PKR",
                                                })}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                {((totalCategoryExpense / totalCategoryExpense) * 100).toFixed(1)}%
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const Card = ({ title, description, data, color }: { title: string, description: string, data: number, color?: string }) => {
    return (
        <div className={`p-6 rounded-lg bg-gray-50 shadow-xl hover:shadow-3xl transition-shadow duration-300 border border-gray-50 ${color ? color : ""}`}>
            <div className="space-y-4">
                <h3 className="text-xl font-bold text-gray-700 border-b border-gray-100 pb-2">{title}</h3>
                {/* <p className="text-sm text-gray-500/80">{description}</p> */}
                <div className="text-2xl font-semibold text-gray-900">
                    {data.toLocaleString("en-US", {
                        style: "currency",
                        currency: "PKR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </div>
            </div>
        </div>
    )
}

export default RevenueSummaryPage

