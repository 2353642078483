import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Select } from "antd";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { categoryValues } from "constants/constantvalues";
import { X } from "@mui/icons-material";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "state-management/user-context";
import { EditIcon, EyeIcon, DeleteIcon } from "shared-components/ui/Icons";
import { Input } from "shared-components/ui/CustomForm";

export const MiscPaymentModal = ({
  ShowModel,
  setShowModel,
  isEditMode,
  selectedMiscPayment,
  fetchMiscPayments,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  isEditMode: boolean;
  selectedMiscPayment: any;
  fetchMiscPayments: () => void;
}) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const { user } = useContext(UserContext);

  const GetServiceProviders = async () => {
    const res2 = await apiRequest("getBankAccounts");
    if (res2.data?.isSuccess) {
      setBankAccounts(res2.data?.bankAccounts);
    }
  };

  useEffect(() => {
    GetServiceProviders();
  }, []);

  interface BreakDownAmounts {
    category: string;
    amount: number;
  }

  const [formData, setFormData] = useState<{
    expenseName: string;
    breakdownAmounts: BreakDownAmounts[];
    paidByOurAccounts: string[];
    addedBy: string;
    modifiedBy: string;
    screenshot: string;
    createdAt: Date;
    description: string;
    payment_date: Date;
  }>({
    expenseName: "",
    breakdownAmounts: [],
    paidByOurAccounts: [],
    addedBy: "",
    modifiedBy: "",
    screenshot: "",
    createdAt: new Date(),
    description: "",
    payment_date: new Date(),
  });

  useEffect(() => {
    if (selectedMiscPayment && isEditMode) {
      setFormData({
        expenseName: selectedMiscPayment?.expenseName,
        breakdownAmounts: selectedMiscPayment?.breakdownAmounts.map(
          (item: any) => ({
            category: item.category,
            amount: item.amount,
          })
        ),
        paidByOurAccounts: selectedMiscPayment?.paidByOurAccounts,
        createdAt: new Date(selectedMiscPayment?.createdAt || new Date()),
        addedBy: selectedMiscPayment?.addedBy,
        modifiedBy: selectedMiscPayment?.modifiedBy,
        screenshot: selectedMiscPayment?.screenshot,
        description: selectedMiscPayment?.description,
        payment_date: new Date(selectedMiscPayment?.payment_date) || new Date(),
      });
    }
  }, [selectedMiscPayment, isEditMode]);

  const handleSubmit = async () => {
    try {
      //validate form data
      if (formData.breakdownAmounts.length === 0) {
        toast.error("Please add at least one breakdown amount");
        return;
      }
      //make sure all breakdown amounts are numbers and not negative
      formData.breakdownAmounts.forEach((item) => {
        if (isNaN(Number(item.amount)) || Number(item.amount) < 0) {
          toast.error("Please enter valid numbers for breakdown amounts");
          return;
        }
      });
      //iterate through breakdown amounts and make sure every field is filled
      formData.breakdownAmounts.forEach((item) => {
        if (!item.category || !item.amount) {
          toast.error("Please fill all fields for breakdown amounts");
          return;
        }
      });
      //make sure expenseName is not empty
      if (!formData.expenseName) {
        toast.error("Please enter an expense name");
        return;
      }
      //make sure total amount is not negative
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ) < 0
      ) {
        toast.error("Total amount cannot be negative");
        return;
      }
      //make sure total amount is not zero
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ) === 0
      ) {
        toast.error("Total amount cannot be zero");
        return;
      }
      //make sure user is logged in
      if (!user?.username) {
        toast.error("Please login to continue");
        return;
      }
      //make sure createdAt is not in the future
      if (formData.createdAt > new Date()) {
        toast.error("Created at cannot be in the future");
        return;
      }
      //make sure paidByOurAccounts is not empty
      if (formData.paidByOurAccounts.length === 0) {
        toast.error("Please select at least one paid by our account");
        return;
      }
      //make sure screenshot is not empty
      if (!formData.screenshot) {
        toast.error("Please upload a screenshot");
        return;
      }
      //make sure payment_date is not in the future
      if (formData.payment_date > new Date()) {
        toast.error("Payment date cannot be in the future");
        return;
      }

      const payload = {
        ...formData,
        totalAmount: formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ),
        addedBy: isEditMode ? selectedMiscPayment?.addedBy : user?.username,
        modifiedBy: isEditMode
          ? selectedMiscPayment?.modifiedBy
          : user?.username,
      };

      const res = await apiHandler({
        data: payload,
        name: isEditMode
          ? "updateMiscellaneousPayment"
          : "addMiscellaneousPayment",
        params: isEditMode ? [`${selectedMiscPayment?._id}`] : [],
      });

      if (res.data?.isSuccess) {
        toast.success("Payment submitted successfully");

        setShowModel(false);
        // Reset form
        setFormData({
          expenseName: "",
          breakdownAmounts: [],
          paidByOurAccounts: [],
          addedBy: "",
          modifiedBy: "",
          screenshot: "",
          createdAt: new Date(),
          description: "",
          payment_date: new Date(),
        });
        fetchMiscPayments();
        window.location.reload();
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      toast.error("Payment submission error");
    }
  };

  const convertToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-4xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {isEditMode ? "Update" : "Add New"} Payment
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setFormData({
                  expenseName: "",
                  breakdownAmounts: [],
                  paidByOurAccounts: [],
                  addedBy: "",
                  modifiedBy: "",
                  createdAt: new Date(),
                  screenshot: "",
                  description: "",
                  payment_date: new Date(),
                });
                setShowModel(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/*SELECT EMPLOYEE*/}
          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-2">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="expenseName">Expense Name</label>
                <Input
                  name="expenseName"
                  value={formData.expenseName}
                  onChange={(name, value) =>
                    setFormData({ ...formData, [name]: value })
                  }
                />
              </div>
            </div>

            {/* Breakdown Amounts Section */}
            <div className="mt-4">
              <label className="block mb-2">Payment Breakdown</label>
              {formData.breakdownAmounts.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg"
                >
                  <CustomDropDown
                    options={categoryValues.map((category) => category.value)}
                    onSelect={(value) => {
                      const newBreakdown = [...formData.breakdownAmounts];
                      newBreakdown[index].category = value;
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    value={item.category}
                    label="Category"
                    width="w-full"
                  />

                  <div className="flex flex-col gap-2">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="border p-2 rounded w-full bg-transparent"
                      value={item.amount || ""}
                      onChange={(e) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].amount =
                          e.target.value === "" ? 0 : Number(e.target.value);
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                    />
                  </div>

                  <X
                    onClick={() => {
                      const newBreakdown = formData.breakdownAmounts.filter(
                        (_, i) => i !== index
                      );
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                  />
                </div>
              ))}
              <ButtonFill
                handleClick={() => {
                  setFormData({
                    ...formData,
                    breakdownAmounts: [
                      ...formData.breakdownAmounts,
                      {
                        category: "",
                        amount: 0,
                      },
                    ],
                  });
                }}
              >
                Add Breakdown
              </ButtonFill>
            </div>

            {/* Paid By Our Accounts Section */}
            <div className="mt-4">
              <label className="block mb-2">Paid By Our Accounts</label>
              <Select
                placeholder="Select paid by our accounts"
                options={bankAccounts.map((bankAccount) => ({
                  label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                  value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                }))}
                onChange={(value) => {
                  setFormData({ ...formData, paidByOurAccounts: value });
                }}
                className="w-full h-10 border border-gray-300 font-rubik"
                bordered={false}
                value={formData.paidByOurAccounts}
                mode="multiple"
              />
            </div>

            {/* Added By Section */}

            {/* Created At Section */}
            <div className="mt-4">
              <label className="block mb-2">For Month</label>
              <input
                type="date"
                placeholder="Created At"
                className="border p-2 rounded"
                value={formData.createdAt.toISOString().split("T")[0]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    createdAt: new Date(e.target.value),
                  })
                }
              />
            </div>

            <div className="mt-4">
              <label className="block mb-2">Payment Date</label>
              <input
                type="date"
                placeholder="Payment Date"
                className="border p-2 rounded"
                value={formData.payment_date.toISOString().split("T")[0]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    payment_date: new Date(e.target.value),
                  })
                }
              />
            </div>

            {/* Screenshot Section */}
            <div className="mt-4">
              <label className="block mb-2">Screenshot</label>
              <input
                type="file"
                className="border p-2 rounded"
                accept="image/png, image/jpeg"
                onChange={async (e) => {
                  //upload in base64
                  const file = e.target.files?.[0];
                  if (file) {
                    const base64 = await convertToBase64(file);
                    setFormData({ ...formData, screenshot: base64 as string });
                  }
                }}
              />
              {formData.screenshot && (
                <img
                  src={formData.screenshot}
                  alt="Screenshot"
                  className="w-24 h-24 object-fit border mt-2 rounded"
                />
              )}
            </div>

            {/* Description Section */}
            <div className="mt-4">
              <label className="block mb-2">Description</label>
              <Input
                value={formData.description}
                onChange={(name, value) =>
                  setFormData({ ...formData, [name]: value })
                }
                name="description"
              />
            </div>

            {/* Submit Button */}
            <div className="mt-4 flex justify-end">
              <ButtonFill
                handleClick={handleSubmit}
                disabled={!formData.expenseName}
              >
                {isEditMode ? "Update" : "Submit"} Payment
              </ButtonFill>
            </div>
            <Toaster />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewMiscPaymentModal = ({
  ShowModel,
  setShowModel,
  selectedMiscPayment,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  selectedMiscPayment: any;
}) => {
  const GetDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div
      id="viewPaymentModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-6xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              View Payment
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setShowModel(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/* TODO: Add Payment Details */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <b>Expense Name:</b> {selectedMiscPayment?.expenseName}
            </div>
            <div className="flex flex-row gap-2">
              <b>Amount Paid:</b> {selectedMiscPayment?.totalAmount}
            </div>
            <div className="flex flex-col gap-2">
              <b>Paid By Our Accounts:</b>
              <div className="flex flex-row gap-2">
                {selectedMiscPayment?.paidByOurAccounts.map(
                  (account: any, index: number) => (
                    <div key={index}>
                      <b>{index + 1}.</b> {account}
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <b>Breakdown Amounts:</b>
              <div className="flex flex-row gap-2 w-full">
                <table className="w-full">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Category
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedMiscPayment?.breakdownAmounts.map(
                      (item: any, index: number) => (
                        <tr key={index} className="border-b">
                          <td className="px-4 py-2 text-center">{index + 1}</td>
                          <td className="px-4 py-2 text-center">
                            {item?.category}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {item?.amount}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex flex-row gap-2 justify-between">
              <div className="flex justify-start gap-2">
                <b>Added By:</b> {selectedMiscPayment?.addedBy}
              </div>
              <div className="flex justify-end gap-2">
                <b>Expense Date:</b> {GetDate(selectedMiscPayment?.createdAt)}
              </div>
              <div className="flex justify-end gap-2">
                <b>Payment Date:</b>{" "}
                {GetDate(selectedMiscPayment?.payment_date)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MiscPaymentsTable = ({
  miscPayments,
  setOpenAddMiscPaymentModal,
  setModalType,
  setSelectedMiscPayment,
  setOpenViewMiscPaymentModal,
  GetDate,
  fetchMiscPayments,
}: {
  miscPayments: any[];
  setOpenAddMiscPaymentModal: (value: boolean) => void;
  setModalType: (value: string) => void;
  setSelectedMiscPayment: (value: any) => void;
  setOpenViewMiscPaymentModal: (value: boolean) => void;
  GetDate: (date: string) => string;
  fetchMiscPayments: () => void;
}) => {
  const DeleteMiscPayment = async (id: string) => {
    try {
      const response = await apiHandler({
        name: "deleteMiscPayment",
        params: [id],
      });
      if (response.data.isSuccess) {
        fetchMiscPayments();
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete miscellaneous payment");
      console.error(error);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(miscPayments);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    field: "",
    direction: "asc",
  });

  // New filter states
  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [expenseName, setExpenseName] = useState("");
  const [amountFilterType, setAmountFilterType] = useState("exact");
  const [amountStart, setAmountStart] = useState("");
  const [amountEnd, setAmountEnd] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get unique categories from all payments
  const categories = useMemo(() => {
    const cats = new Set(
      miscPayments
        .filter(
          (payment) =>
            payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
        )
        .flatMap((payment) =>
          payment.breakdownAmounts
            .filter((breakdown) => breakdown?.category)
            .map((breakdown) => breakdown.category)
        )
    );
    return Array.from(cats).filter((cat) => cat);
  }, [miscPayments]);

  const handleFilters = () => {
    let filtered = [...miscPayments];

    // Filter by expense date range
    if (expenseStartDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.createdAt) >= new Date(expenseStartDate)
      );
    }
    if (expenseEndDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.createdAt) <= new Date(expenseEndDate)
      );
    }

    // Filter by payment date range
    if (paymentStartDate) {
      filtered = filtered.filter(
        (payment) =>
          new Date(payment.payment_date) >= new Date(paymentStartDate)
      );
    }
    if (paymentEndDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.payment_date) <= new Date(paymentEndDate)
      );
    }

    // Filter by expense name
    if (expenseName) {
      filtered = filtered.filter((payment) =>
        payment?.expenseName.toLowerCase().includes(expenseName.toLowerCase())
      );
    }

    // Enhanced amount filtering logic
    if (amountFilterType === "exact" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount === Number(amountStart)
      );
    } else if (amountFilterType === "less" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount < Number(amountStart)
      );
    } else if (amountFilterType === "greater" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount > Number(amountStart)
      );
    } else if (amountFilterType === "range" && amountStart && amountEnd) {
      filtered = filtered.filter(
        (payment) =>
          payment.totalAmount >= Number(amountStart) &&
          payment.totalAmount <= Number(amountEnd)
      );
    }

    // Filter by description
    if (descriptionSearch) {
      filtered = filtered.filter((payment) =>
        payment?.description
          ?.toLowerCase()
          .includes(descriptionSearch.toLowerCase())
      );
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter((payment) =>
        payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
          ? payment.breakdownAmounts.some(
              (breakdown) => breakdown?.category === selectedCategory
            )
          : false
      );
    }

    // Apply sorting if selected
    if (sortConfig.field) {
      filtered.sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.field) {
          case "expenseName":
            aValue = (a.expenseName || "").toLowerCase().trim();
            bValue = (b.expenseName || "").toLowerCase().trim();
            return sortConfig.direction === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case "totalAmount":
            aValue = a.totalAmount || 0;
            bValue = b.totalAmount || 0;
            break;
          case "createdAt":
            aValue = new Date(a.createdAt).getTime();
            bValue = new Date(b.createdAt).getTime();
            break;
          case "payment_date":
            aValue = new Date(a.payment_date).getTime();
            bValue = new Date(b.payment_date).getTime();
            break;
          case "description":
            aValue = (a.description || "").toLowerCase().trim();
            bValue = (b.description || "").toLowerCase().trim();
            return sortConfig.direction === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          default:
            return 0;
        }

        if (sortConfig.direction === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setExpenseStartDate("");
    setExpenseEndDate("");
    setPaymentStartDate("");
    setPaymentEndDate("");
    setExpenseName("");
    setAmountFilterType("exact");
    setAmountStart("");
    setAmountEnd("");
    setDescriptionSearch("");
    setSelectedCategory("");
    setSortConfig({ field: "", direction: "asc" });
    setFilteredData(miscPayments);
    setCurrentPage(1);
  };

  const getPageData = () => {
    const startIndex = (currentPage - 1) * 20;
    const endIndex = startIndex + 20;
    return filteredData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        {/* Expense Date Filters */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Expense Date Range</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={expenseStartDate}
              onChange={(e) => setExpenseStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={expenseEndDate}
              onChange={(e) => setExpenseEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div>

        {/* Payment Date Filters */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Payment Date Range</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={paymentStartDate}
              onChange={(e) => setPaymentStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={paymentEndDate}
              onChange={(e) => setPaymentEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div>

        {/* Expense Name Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Expense Name</label>
          <input
            type="text"
            placeholder="Search expense name..."
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Amount Search with Enhanced Options */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Amount</label>
          <div className="flex gap-2">
            <select
              value={amountFilterType}
              onChange={(e) => {
                setAmountFilterType(e.target.value);
                setAmountStart("");
                setAmountEnd("");
              }}
              className="border p-2 rounded bg-transparent w-1/3"
            >
              <option value="exact">Exact</option>
              <option value="less">Less than</option>
              <option value="greater">Greater than</option>
              <option value="range">Range</option>
            </select>
            <div className="flex gap-2 w-2/3">
              {amountFilterType === "range" ? (
                <>
                  <input
                    type="number"
                    placeholder="From"
                    value={amountStart}
                    onChange={(e) => setAmountStart(e.target.value)}
                    className="border p-2 rounded w-1/2 bg-transparent"
                  />
                  <input
                    type="number"
                    placeholder="To"
                    value={amountEnd}
                    onChange={(e) => setAmountEnd(e.target.value)}
                    className="border p-2 rounded w-1/2 bg-transparent"
                  />
                </>
              ) : (
                <input
                  type="number"
                  placeholder={
                    amountFilterType === "exact"
                      ? "Enter amount"
                      : amountFilterType === "less"
                      ? "Less than"
                      : "Greater than"
                  }
                  value={amountStart}
                  onChange={(e) => setAmountStart(e.target.value)}
                  className="border p-2 rounded w-full bg-transparent"
                />
              )}
            </div>
          </div>
        </div>

        {/* Description Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Description</label>
          <input
            type="text"
            placeholder="Search description..."
            value={descriptionSearch}
            onChange={(e) => setDescriptionSearch(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Category Filter */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Category</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        {/* Sort Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Sort By</label>
          <div className="flex gap-2">
            <select
              value={sortConfig.field}
              onChange={(e) =>
                setSortConfig((prev) => ({ ...prev, field: e.target.value }))
              }
              className="border p-2 rounded bg-transparent w-2/3"
            >
              <option value="">No Sort</option>
              <option value="expenseName">Expense Name</option>
              <option value="totalAmount">Amount</option>
              <option value="createdAt">Expense Date</option>
              <option value="payment_date">Payment Date</option>
            </select>
            <select
              value={sortConfig.direction}
              onChange={(e) =>
                setSortConfig((prev) => ({
                  ...prev,
                  direction: e.target.value,
                }))
              }
              className="border p-2 rounded bg-transparent w-1/3"
            >
              <option value="asc">Asc</option>
              <option value="desc">Desc</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 mb-4">
        <button
          className="bg-primary text-white p-2 rounded"
          onClick={handleFilters}
        >
          Apply Filters
        </button>
        <button
          className="bg-gray-500 text-white p-2 rounded"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
      </div>

      <div className="flex flex-row mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setFilteredData(
              miscPayments.filter(
                (payment) =>
                  payment?.expenseName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  GetDate(payment?.createdAt).includes(e.target.value)
              )
            );
            setCurrentPage(1);
          }}
          className="border p-2 rounded w-full bg-transparent"
        />
        <button
          className="bg-primary text-white p-2 rounded"
          onClick={() => {
            setSearchText("");
            setFilteredData(miscPayments);
            setCurrentPage(1);
          }}
        >
          Reset
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Expense Date
              </th>
              <th scope="col" className="px-4 py-4">
                Payment Date
              </th>
              <th scope="col" className="px-4 py-4">
                Expense Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Amount Paid
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Description
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Screenshot
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {getPageData().map((payment) => (
              <tr className="border-b" key={payment?._id}>
                <td className="px-4 py-3">{GetDate(payment?.createdAt)}</td>
                <td className="px-4 py-3">{GetDate(payment?.payment_date)}</td>
                <td className="px-4 py-3">{payment?.expenseName}</td>
                <td className="px-4 py-3">{payment?.totalAmount}</td>
                <td className="px-4 py-3">{payment?.description}</td>
                <td className="px-4 py-3">
                  <div
                    className="text-blue-500 cursor-pointer underline"
                    onClick={() => {
                      window.open(payment?.screenshot, "_blank");
                    }}
                  >
                    Screenshot Link
                  </div>
                </td>
                <td className="px-4 py-3 text-center flex items-center justify-center">
                  <div className="cursor-pointer flex items-center gap-4">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenAddMiscPaymentModal(true);
                        setModalType("edit");
                        setSelectedMiscPayment(payment);
                      }}
                    >
                      <EditIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenViewMiscPaymentModal(true);
                        setSelectedMiscPayment(payment);
                      }}
                    >
                      <EyeIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        DeleteMiscPayment(payment?._id);
                      }}
                    >
                      <DeleteIcon size="w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row gap-2 justify-center items-center">
        <button
          disabled={currentPage === 1}
          className="bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10">
          {currentPage}
        </div>
        <button
          disabled={currentPage === Math.ceil(filteredData.length / 10)}
          className="bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};
