import React, { useEffect, useState } from 'react'
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import { PaymentModal, ViewPaymentModal, InternalPaymentsTable } from './components/payment-modal';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { Toaster } from 'react-hot-toast';
import { ExternalPaymentModal, ExternalPaymentsTable, ViewExternalPaymentModal } from './components/external-payment-modal';
import { MiscPaymentModal, ViewMiscPaymentModal, MiscPaymentsTable } from './components/misc-payment-modal';
import { IncomingRevenueModal, InternalUnTrackedRevenueTable, ViewUnTrackedPaymentModal } from './components/incoming-revenue';
import { TaxPaymentModal, TaxPaymentTable, ViewTaxPaymentModal } from './components/tax-payments';

export default function Index() {

  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [openViewPaymentModal, setOpenViewPaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [modalType, setModalType] = useState("add");
  const [internalPayments, setInternalPayments] = useState([]);
  const [externalPayments, setExternalPayments] = useState([]);
  const [miscPayments, setMiscPayments] = useState([]);
  const [unTrackedPayments, setUnTrackedPayments] = useState([]);
  const [taxPayments, setTaxPayments] = useState([]);
  const [activeTab, setActiveTab] = useState("");
   
  const fetchInternalPayments = async () => {
    const res = await apiRequest("getInternalPayments");
    if (res.data?.isSuccess) {
      setInternalPayments(res.data?.payments);
      setActiveTab("internal");
    }
  }

  const fetchExternalPayments = async () => {
    const res = await apiRequest("getExternalPayments");
    if (res.data?.isSuccess) {
      setExternalPayments(res.data?.payments);
    }
  }

  const fetchMiscPayments = async () => {
    const res = await apiRequest("getAllMiscellaneousPayments");
    if (res.data?.isSuccess) {
      setMiscPayments(res.data?.payments);
    }
  }

  const fetchUnTrackedPayments = async () => {
    const res = await apiRequest("getUnTrackedRevenue");
    if (res.data?.isSuccess) {
      setUnTrackedPayments(res.data?.revenue);
    }
  }

  const fetchTaxPayments = async () => {
    const res = await apiRequest("getTaxReturns");
    if (res.data?.isSuccess) {
      setTaxPayments(res.data?.taxReturns);
    }
  }

  useEffect(() => {
    fetchInternalPayments();
    fetchExternalPayments();
    fetchMiscPayments();
    fetchUnTrackedPayments();
    fetchTaxPayments();
  }, []);

  const GetDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  const [openAddExternalPaymentModal, setOpenAddExternalPaymentModal] = useState(false);
  const [selectedExternalPayment, setSelectedExternalPayment] = useState(null);
  const [openViewExternalPaymentModal, setOpenViewExternalPaymentModal] = useState(false);
  const [tabs, setTabs] = useState([
    {
      label: "Internal Payments",
      value: "internal"
    },
    {
      label: "External Payments",
      value: "external"
    },
    {
      label: "Miscellaneous Payments",
      value: "miscellaneous"
    },
    {
      label: "Incoming Revenue",
      value: "untracked"
    },
    {
      label: "Tax Receipts",
      value: "tax-receipts"
    }
  ]);

  const [openAddMiscPaymentModal, setOpenAddMiscPaymentModal] = useState(false);
  const [selectedMiscPayment, setSelectedMiscPayment] = useState(null);
  const [openViewMiscPaymentModal, setOpenViewMiscPaymentModal] = useState(false);

  const [openAddUnTrackedPaymentModal, setOpenAddUnTrackedPaymentModal] = useState(false);
  const [selectedUnTrackedPayment, setSelectedUnTrackedPayment] = useState(null);
  const [openViewUnTrackedPaymentModal, setOpenViewUnTrackedPaymentModal] = useState(false);

  const [openAddTaxPaymentModal, setOpenAddTaxPaymentModal] = useState(false);
  const [selectedTaxPayment, setSelectedTaxPayment] = useState(null);
  const [openViewTaxPaymentModal, setOpenViewTaxPaymentModal] = useState(false);

  return (
    <div className="w-[95%] mx-auto pb-20 px-5">
      {/*tabs*/}
      <div className='flex flex-row gap-2 border border-gray-200 rounded-md shadow-sm p-2 w-fit'>
        {tabs.map((tab) => (
          <div key={tab.value} className={`${activeTab === tab.value ? "bg-primary text-white" : "bg-white text-gray-700"} px-4 py-2 rounded-md cursor-pointer`} onClick={() => setActiveTab(tab.value)}>
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between pb-4 mt-5">
        <h2 className="text-2xl font-bold text-gray-700">{tabs.find((tab) => tab.value === activeTab)?.label}</h2>

        {activeTab === "internal" && (
          <ButtonFill
            handleClick={() => {
              setOpenAddPaymentModal(true);
              setModalType("add");
            }}
          >
            Add Internal Payment
          </ButtonFill>
        )}

        {activeTab === "external" && (
          <ButtonFill
            handleClick={() => {
              setOpenAddExternalPaymentModal(true);
              setModalType("add");
            }}
          >
            Add External Payment
          </ButtonFill>
        )}

        {activeTab === "miscellaneous" && (
          <ButtonFill
            handleClick={() => {
              setOpenAddMiscPaymentModal(true);
              setModalType("add");
            }}
          >
            Add Miscellaneous Payment
          </ButtonFill>
        )}

        {activeTab === "untracked" && (
          <ButtonFill
            handleClick={() => {
              setOpenAddUnTrackedPaymentModal(true);
              setModalType("add");
            }}
          >
            Add Revenue
          </ButtonFill>
        )}

        {activeTab === "tax-receipts" && (
          <ButtonFill
            handleClick={() => {
              setOpenAddTaxPaymentModal(true);
              setModalType("add");
            }}
          >
            Add Tax Receipt
          </ButtonFill>
        )}
      </div>

      <PaymentModal
        ShowModel={openAddPaymentModal}
        setShowModel={setOpenAddPaymentModal}
        isEditMode={modalType === "edit"}
        selectedPayment={selectedPayment}
        setModalType={setModalType}
        fetchInternalPayments={fetchInternalPayments}
      />

      <ViewPaymentModal
        ShowModel={openViewPaymentModal}
        setShowModel={setOpenViewPaymentModal}
        selectedPayment={selectedPayment}
      />

      <ExternalPaymentModal
        ShowModel={openAddExternalPaymentModal}
        setShowModel={setOpenAddExternalPaymentModal}
        isEditMode={modalType === "edit"}
        selectedExternalPayment={selectedExternalPayment}
        fetchExternalPayments={fetchExternalPayments}
        setModalType={setModalType}
      />

      <TaxPaymentModal
        ShowModel={openAddTaxPaymentModal}
        setShowModel={setOpenAddTaxPaymentModal}
        isEditMode={modalType === "edit"}
        selectedTaxPayment={selectedTaxPayment}
        fetchTaxPayments={fetchTaxPayments}
        setModalType={setModalType}
      />

      <ViewExternalPaymentModal
        ShowModel={openViewExternalPaymentModal}
        setShowModel={setOpenViewExternalPaymentModal}
        selectedExternalPayment={selectedExternalPayment}
      />

      <ViewMiscPaymentModal
        ShowModel={openViewMiscPaymentModal}
        setShowModel={setOpenViewMiscPaymentModal}
        selectedMiscPayment={selectedMiscPayment}
      />

      <ViewUnTrackedPaymentModal
        ShowModel={openViewUnTrackedPaymentModal}
        setShowModel={setOpenViewUnTrackedPaymentModal}
        selectedUnTrackedPayment={selectedUnTrackedPayment}
      />

      <ViewTaxPaymentModal
        ShowModel={openViewTaxPaymentModal}
        setShowModel={setOpenViewTaxPaymentModal}
        selectedTaxPayment={selectedTaxPayment}
      />

      <MiscPaymentModal
        ShowModel={openAddMiscPaymentModal}
        setShowModel={setOpenAddMiscPaymentModal}
        isEditMode={modalType === "edit"}
        selectedMiscPayment={selectedMiscPayment}
        fetchMiscPayments={fetchMiscPayments}
      />

      <IncomingRevenueModal
        ShowModel={openAddUnTrackedPaymentModal}
        setShowModel={setOpenAddUnTrackedPaymentModal}
        isEditMode={modalType === "edit"}
        selectedUnTrackedPayment={selectedUnTrackedPayment}
        fetchUnTrackedPayments={fetchUnTrackedPayments}
        setModalType={setModalType}
      />

      {activeTab === "external" && (
        <ExternalPaymentsTable
          externalPayments={externalPayments}
          setOpenAddExternalPaymentModal={setOpenAddExternalPaymentModal}
          setModalType={setModalType}
          setSelectedExternalPayment={setSelectedExternalPayment}
          setOpenViewExternalPaymentModal={setOpenViewExternalPaymentModal}
          GetDate={GetDate}
          fetchExternalPayments={fetchExternalPayments}
        />
      )}

      {activeTab === "internal" && (
        <InternalPaymentsTable
          internalPayments={internalPayments}
          setOpenAddPaymentModal={setOpenAddPaymentModal}
          setModalType={setModalType}
          setSelectedPayment={setSelectedPayment}
          setOpenViewPaymentModal={setOpenViewPaymentModal}
          GetDate={GetDate}
          fetchInternalPayments={fetchInternalPayments}
        />
      )}

      {activeTab === "miscellaneous" && (
        <MiscPaymentsTable
          miscPayments={miscPayments}
          setOpenAddMiscPaymentModal={setOpenAddMiscPaymentModal}
          setModalType={setModalType}
          setSelectedMiscPayment={setSelectedMiscPayment}
          setOpenViewMiscPaymentModal={setOpenViewMiscPaymentModal}
          GetDate={GetDate}
          fetchMiscPayments={fetchMiscPayments}
        />
      )}

      {activeTab === "untracked" && (
        <InternalUnTrackedRevenueTable
          unTrackedRevenue={unTrackedPayments}
          setOpenAddRevenueModal={setOpenAddUnTrackedPaymentModal}
          setModalType={setModalType}
          setSelectedRevenue={setSelectedUnTrackedPayment}
          setOpenViewRevenueModal={setOpenViewUnTrackedPaymentModal}
          GetDate={GetDate}
          fetchUnTrackedRevenue={fetchUnTrackedPayments}
        />
      )}

      {activeTab === "tax-receipts" && (
        <TaxPaymentTable
          taxPayments={taxPayments}
          setOpenAddTaxPaymentModal={setOpenAddTaxPaymentModal}
          setModalType={setModalType}
          setSelectedTaxPayment={setSelectedTaxPayment}
          setOpenViewTaxPaymentModal={setOpenViewTaxPaymentModal}
          GetDate={GetDate}
          fetchTaxPayments={fetchTaxPayments}
        />
      )}




      <Toaster />

    </div>
  )
}
