
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { AccessAPI } from '../../../(apis)/(shared-apis)/admin-access-apis';
import { ButtonFill, ButtonOutlined } from '../../../shared-components/ui/CustomButtons';
import { Label } from 'recharts';
import { Select } from 'antd';

export default function PermissionModal({ ShowModel, setShowModel, selectedUser, FetchData, adminUsers }) {

    const [disabled, setDisabled] = useState(false);

    const [PagePermissions, SetPagePermissions] = useState([]);
    const [DeckEditPermissions, SetDeckEditPermissions] = useState([]);
    const [DeckAddPermissions, SetDeckAddPermissions] = useState([]);
    const [OtherPermissions, SetOtherPermissions] = useState([]);
    const [ReportsTo, SetReportsTo] = useState("");
    useEffect(() => {
        SetPagePermissions(selectedUser?.admin.pagepermissions);
        SetDeckEditPermissions(selectedUser?.admin.deckeditpermissions);
        SetDeckAddPermissions(selectedUser?.admin.deckaddpermissions);
        SetOtherPermissions(selectedUser?.admin.otherpermissions);
        SetReportsTo(selectedUser?.admin.reports_to);
    }, [selectedUser?.username, ShowModel]);

    const Submit = () => {
        setDisabled(true);
        AccessAPI("updateAdminUserPermissions", [""], {
            username: selectedUser?.username,
            pagepermissions: PagePermissions,
            deckeditpermissions: DeckEditPermissions,
            deckaddpermissions: DeckAddPermissions,
            otherpermissions: OtherPermissions,
            reports_to: ReportsTo,
        }).then((res: any) => {
            if (res.status === 200) {
                toast.success("Permissions Updated");
                setShowModel(false);
                FetchData();
            } else {
                toast.error("Error Updating Permissions");
            }
            setDisabled(false);
        }).catch((err: any) => {
            toast.error("Error Updating Permissions");
            setDisabled(false);
        })

    }

    const Academics = [
        "Category Access",
        "Add Question",
        "Topic Access",
        "Module Access",
        "Discipline Access",
        "Reference Access",
        "Institution Access",
        "Filter Question",
        'Question Reports',
    ]

    const Vault = [
        "Note Access",
        "Shortlisting Access",
        "Mnemonic Access",
        "Cheatsheet Access",
        "Essentials Access",
        "SnapCourse Access",
        "Guide Access",
    ]

    const Expert = [
        "Expert Solutions",
        "Expert Biology",
        "Expert Chemistry",
        "Expert Maths",
        "Expert Physics",
        "Expert Science",
        "Expert Logical",
        "Expert English",
        "Expert Access",
        "Admin Expert Access",
    ]

    const Plans = [
        "Plan Access",
    ]

    const Marketing = [
        "Conversions Manager",
        "Marketing Access",
        "Free Trial Access",
    ]

    const Extras = [
        "User Manager",
        "Head Quarter",
        "Testimonials Page Access",
        "Teams Page Access",
    ]

    const Finance = [
        "Order Access",
        "Course Access",
        "Payments Management",
        "Revenue Access",
        "Order Statistics",
        "Verification Access",
    ]

    const otherPermissions = [
        "ShowQuestionID",
        "Frontend Edit Page",
    ]

    const CourseManager = [
        "Course Manager",
        "Courses Intern"
    ]
    return (
        <>
            <Toaster />
            <div
                id="createProductModal"
                tabIndex={-1}
                aria-hidden="true"
                className={`${ShowModel ? "" : "hidden"
                    } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
            >
                <div className="relative w-full max-w-4xl max-h-full p-4">
                    <div className="relative p-4 bg-white rounded-lg shadow ">
                        <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                            <h3 className="text-lg font-semibold text-gray-900 ">
                                Set User Permissions
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                                data-modal-target="createProductModal"
                                data-modal-toggle="createProductModal"
                                onClick={() => {
                                    setShowModel(false);
                                }}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="space-y-3 mt-3">

                            <div className="grid grid-cols-3 gap-4">

                                <div className="flex flex-col gap-2">
                                    <h3 className="text-lg font-semibold text-gray-900 ">
                                        Academics Permissions
                                    </h3>
                                    {Academics.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}
                                    <h3 className="text-lg font-semibold text-gray-900 mt-4">
                                        Marketing Permissions
                                    </h3>
                                    {Marketing.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}
                                    <h3 className="text-lg font-semibold text-gray-900 mt-4">
                                        Plans Permissions
                                    </h3>
                                    {Plans.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}
                                    <h3 className="text-lg font-semibold text-gray-900 mt-4">
                                        Finance Permissions
                                    </h3>
                                    {Finance.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}

                                </div>
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-lg font-semibold text-gray-900 ">
                                        Vault Permissions
                                    </h3>
                                    {Vault.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}

                                    <h3 className='text-lg font-semibold text-gray-900 mt-4'>
                                        Extras Permissions
                                    </h3>
                                    {Extras.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}

                                    <h3 className='text-lg font-semibold text-gray-900 mt-4'>
                                        Course Manager Permissions
                                    </h3>
                                    {CourseManager.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-lg font-semibold text-gray-900 ">
                                        Expert Permissions
                                    </h3>
                                    {Expert.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={PagePermissions && PagePermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetPagePermissions([...PagePermissions, permission]);
                                                    } else {
                                                        SetPagePermissions(PagePermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}

                                    <h3 className='text-lg font-semibold text-gray-900 mt-4'>
                                        Other Permissions
                                    </h3>
                                    {otherPermissions.map((permission, index) => (
                                        <div key={index} className="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                id={permission}
                                                name={permission}
                                                checked={OtherPermissions && OtherPermissions.includes(permission)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        SetOtherPermissions([...OtherPermissions, permission]);
                                                    } else {
                                                        SetOtherPermissions(OtherPermissions.filter((item) => item !== permission));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={permission}>{permission}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="space-y-2">
                                    <Label>Reports to</Label>
                                    <Select
                                        options={adminUsers.map((user: any) => ({
                                            label: user.fullname,
                                            value: user.username
                                        }))}
                                        onSelect={(value) => SetReportsTo(value)}
                                        value={ReportsTo}
                                        className="w-full !h-[40px] border-gray-300 border rounded-md"
                                        showSearch
                                        optionFilterProp="label"
                                        placeholder="Select Reports to"
                                        bordered={false}
                                    />

                                </div>


                            </div>


                            <div className="flex items-center justify-end gap-2">
                                <ButtonOutlined
                                    handleClick={() => {
                                        setShowModel(false);
                                    }}
                                >
                                    Cancel
                                </ButtonOutlined>
                                <ButtonFill
                                    handleClick={Submit}
                                    disabled={disabled}
                                >
                                    Update Permissions
                                </ButtonFill>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
