import { ApiInterface } from "../api-interface/types";

const accountsApis = [
  {
    url: "/api/finance/getAllAccounts",
    method: "GET",
    name: "getAllAccounts",
  },
  {
    url: "/api/finance/addAccount",
    method: "POST",
    name: "addAccount",
  },
  {
    url: "/api/finance/updateAccount/:id",
    method: "PUT",
    name: "updateAccount",
  },
];
const miscellaneousPaymentsApis = [
  {
    url: "/api/finance/getAllMiscellaneousPayments",
    method: "GET",
    name: "getAllMiscellaneousPayments",
  },
  {
    url: "/api/finance/addMiscellaneousPayment",
    method: "POST",
    name: "addMiscellaneousPayment",
  },
  {
    url: "/api/finance/updateMiscellaneousPayment",
    method: "PUT",
    name: "updateMiscellaneousPayment",
  },
];

export const financeApis: ApiInterface[] = [
  // service Provider
  {
    url: "/api/finance/getAllServiceProviders",
    method: "GET",
    name: "getAllServiceProviders",
  },
  {
    url: "/api/finance/addServiceProvider",
    method: "POST",
    name: "addServiceProvider",
  },
  {
    url: "/api/finance/updateServiceProvider/:id",
    method: "PUT",
    name: "updateServiceProvider",
  },
  // employees
  {
    url: "/api/finance/getAllEmployees",
    method: "GET",
    name: "getAllEmployees",
  },
  {
    url: "/api/finance/get-bank-accounts",
    method: "GET",
    name: "getBankAccounts",
  },
  {
    url: "/api/finance/addEmployee",
    method: "POST",
    name: "addEmployee",
  },
  {
    url: "/api/finance/updateEmployee/:id",
    method: "PUT",
    name: "updateEmployee",
  },
  {
    url: "/api/finance/deleteEmployee/:id",
    method: "PUT",
    name: "deleteEmployee",
  },
  // Payments
  {
    url: "/api/finance/getAllPayments",
    method: "GET",
    name: "getAllPayments",
  },
  {
    url: "/api/finance/getAllPaymentsServiceProviderAndEmployee",
    method: "GET",
    name: "getAllPaymentsServiceProviderAndEmployee",
  },
  {
    url: "/api/finance/addPayment",
    method: "POST",
    name: "addPayment",
  },
  {
    url: "/api/finance/updatePayment/:id",
    method: "PUT",
    name: "updatePayment",
  },
  {
    url: "/api/finance/deletePayment/:id",
    method: "PUT",
    name: "deletePayment",
  },
  {
    url: "/api/finance/add-internal-payment",
    method: "POST",
    name: "createInternalPayment",
  },
  {
    url: "/api/finance/get-internal-payments",
    method: "GET",
    name: "getInternalPayments",
  },
  {
    url: "/api/finance/update-internal-payment",
    method: "PUT",
    name: "updateInternalPayment",
  },
  // external payments
  {
    url: "/api/finance/get-service-providers",
    method: "GET",
    name: "getServiceProviders",
  },
  {
    url: "/api/finance/add-external-payment",
    method: "POST",
    name: "createExternalPayment",
  },
  {
    url: "/api/finance/update-external-payment",
    method: "PUT",
    name: "updateExternalPayment",
  },
  {
    url: "/api/finance/get-external-payments",
    method: "GET",
    name: "getExternalPayments",
  },
  {
    url: "/api/finance/delete-internal-payment",
    method: "DELETE",
    name: "deleteInternalPayment",
  },
  {
    url: "/api/finance/delete-external-payment",
    method: "DELETE",
    name: "deleteExternalPayment",
  },
  {
    url: "/api/finance/delete-miscellaneous-payment",
    method: "DELETE",
    name: "deleteMiscPayment",
  },
  // payment history
  {
    url: "/api/finance/get-payments",
    method: "GET",
    name: "getPaymentHistory",
  },
  {
    url: "/api/finance/get-untracked-revenue",
    method: "GET",
    name: "getUnTrackedRevenue",
  },
  {
    url: "/api/finance/add-untracked-revenue",
    method: "POST",
    name: "addUnTrackedRevenue",
  },
  {
    url: "/api/finance/update-untracked-revenue",
    method: "PUT",
    name: "updateUnTrackedRevenue",
  },
  {
    url: "/api/finance/delete-untracked-revenue",
    method: "DELETE",
    name: "deleteUnTrackedRevenue",
  },
  // tax returns
  {
    url: "/api/finance/add-tax-returns",
    method: "POST",
    name: "addTaxReturns",
  },
  {
    url: "/api/finance/get-tax-returns",
    method: "GET",
    name: "getTaxReturns",
  },
  {
    url: "/api/finance/update-tax-returns",
    method: "PUT",
    name: "updateTaxReturns",
  },
  {
    url: "/api/finance/delete-tax-returns",
    method: "DELETE",
    name: "deleteTaxReturns",
  },
  
  ...accountsApis,
  ...miscellaneousPaymentsApis,
];