import {
  ButtonFill,
  ButtonOutlined,
} from "../../shared-components/ui/CustomButtons";
import CustomBadges from "../../shared-components/ui/CustomBadges";
import { useState } from "react";
import { SearchIcon } from "../../shared-components/ui/Icons";
import CommissionUserForm from "./components/CommissionUserForm";

export const roleHierarchy = {
  ASSOCIATE: "HEAD",
  HEAD: "MANAGER",
  MANAGER: "PROVINCIAL_DIRECTOR",
  PROVINCIAL_DIRECTOR: "NATIONAL_DIRECTOR",
  NATIONAL_DIRECTOR: "",
};

export const ROlE_WORKING_UNDER = {
  ASSOCIATE: "NO ONE",
  MANAGER: "ASSOCIATE",
  DIRECTOR: "MANAGER",
};
export const STATUS = {
  Approved: "Approved",
  Rejected: "Rejected",
  Pending: "Pending",
};

export const ROlES = {
  ASSOCIATE: "ASSOCIATE",
  HEAD: "HEAD",
  MANAGER: "MANAGER",
  PROVINCIAL_DIRECTOR: "PROVINCIAL_DIRECTOR",
  DIRECTOR: "DIRECTOR",
  NATIONAL_DIRECTOR: "NATIONAL_DIRECTOR",
};

export const renderCellColor = (role) => {
  if (role === ROlES.ASSOCIATE) {
    return "bg-gray-100 border border-gray-500";
  } else if (role === ROlES.HEAD) {
    return "bg-teal-200";
  } else if (role === ROlES.MANAGER) {
    return "bg-amber-100/50 border border-amber-500";
  } else if (role === ROlES.PROVINCIAL_DIRECTOR) {
    return "bg-red-200";
  } else if (role === ROlES.NATIONAL_DIRECTOR) {
    return "bg-emerald-100/50 border border-emerald-500";
  } else if (role === ROlES.DIRECTOR) {
    return "bg-emerald-100/50 border border-emerald-500";
  }
};

export const renderChips = (
  role: string,
  textSize:
    | "sm"
    | "lg"
    | "xs"
    | "base"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl" = "xs",
  firstCharacterOnly = false
) => {
  if (role === ROlES.ASSOCIATE) {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="gray"
        showIcon={false}
        textSize={textSize}
      />
    );
  } else if (role === ROlES.HEAD) {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="info"
        showIcon={false}
        textSize={textSize}
      />
    );
  } else if (role === ROlES.MANAGER) {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="warning"
        showIcon={false}
        textSize={textSize}
      />
    );
  } else if (role === ROlES.PROVINCIAL_DIRECTOR) {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="error"
        showIcon={false}
        textSize={textSize}
      />
    );
  } else if (role === ROlES.NATIONAL_DIRECTOR || role === ROlES.DIRECTOR) {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="success"
        showIcon={false}
        textSize={textSize}
      />
    );
  } else {
    return (
      <CustomBadges
        label={
          firstCharacterOnly && role?.length > 1 ? role.substring(0, 1) : role
        }
        type="success"
        showIcon={false}
        textSize={textSize}
      />
    );
  }
};

export const renderStatusChips = (status: string) => {
  console.log(status);
  if (status === STATUS.Approved) {
    return (
      <span className="inline-flex items-center bg-green-100 text-green-800 border border-green-800 text-xs font-medium px-2.5 py-1 rounded-full ">
        <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
        {status}
      </span>
    );
  } else if (status === STATUS.Rejected) {
    return (
      <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-1 rounded-full border border-red-800">
        <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
        {status}
      </span>
    );
  } else if (status === STATUS.Pending) {
    return (
      <span className="inline-flex items-center bg-amber-100 text-amber-800 text-xs font-medium px-2.5 py-1 rounded-full border border-amber-800">
        <span className="w-2 h-2 me-1 bg-amber-500 rounded-full"></span>
        {status}
      </span>
    );
  } else if (status) {
    return (
      <span className="inline-flex items-center bg-green-100 text-green-800 border border-green-800 text-xs font-medium px-2.5 py-1 rounded-full ">
        <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
        {status}
      </span>
    );
  }
};

export const getUsersOfSpecificRole = (allusers, role) => {
  const filteredUserrs = allusers
    ? allusers?.filter((user) => user?.role === role)
    : [];
  return filteredUserrs;
};

export const CommissionBanner = ({
  title,
  setRefreshData,
  setFetchNestedStructure = null,
  fetchNestedStructureAuto = true,
  isFetchingNestedStructure = false,
}) => {
  return (
    <div className="flex items-center justify-between pb-1 mt-5">
      <h2 className="text-2xl font-bold text-gray-700">{title}</h2>

      <div className="gap-2 flex ">
        {!fetchNestedStructureAuto && setFetchNestedStructure !== null && (
          <ButtonOutlined
            handleClick={() => {
              if (isFetchingNestedStructure) return;
              setFetchNestedStructure((prev) => prev + 1);
            }}
          >
            {isFetchingNestedStructure ? "loading.." : " Fetch Hierarchy"}
          </ButtonOutlined>
        )}
        <ButtonOutlined
          handleClick={() => {
            setRefreshData((prev) => prev + 1);
          }}
        >
          Refresh
        </ButtonOutlined>
      </div>
    </div>
  );
};

export const getTotalAmountEarned = (user) => {
  const orders = user?.orders && user?.orders?.length > 0 ? user?.orders : [];
  let totalAmountEarned = 0;

  orders.map((order) => {
    totalAmountEarned = totalAmountEarned + order?.totalAmountEarned;
  });

  return totalAmountEarned?.toFixed();
};

export const formatNumberWithCommas = (number: string | number): string => {
  if (typeof number === "number") {
    return Math.floor(number).toLocaleString();
  } else if (typeof number === "string" && !isNaN(Number(number))) {
    return Math.floor(parseFloat(number)).toLocaleString();
  } else {
    return number;
  }
};

export const REQUEST_STATUS = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
};

export const ROLES_OPTION = [
  "ASSOCIATE",
  // "HEAD",
  "MANAGER",
  "DIRECTOR",
  // "PROVINCIAL_DIRECTOR",
  // "NATIONAL_DIRECTOR",
];

export const getUserIdField = (role: string) => {
  switch (role) {
    case ROlES.DIRECTOR:
      return "managerIds";
    case ROlES.MANAGER:
      return "associateIds";
    default:
      return null;
    // case "PROVINCIAL_DIRECTOR":
    //   return "managerIds";

    // case "HEAD":
    //   return "associateIds";
  }
};

export const transferTypes = [
  {
    name: "Transfer Associates from One Manager to Another Manager",
    value: "transfer-associates-from-one-manager-to-another-manager",
    transferringRole: ROlES.ASSOCIATE,
    supervisorRole: ROlES.MANAGER,
  },
  // {
  //   name: "Transfer Heads from One Manager to Another Manager",
  //   value: "transfer-heads-from-one-manger-to-another-manger",
  //   transferringRole: ROlES.HEAD,
  //   supervisorRole: ROlES.MANAGER,
  // },
  {
    name: "Transfer Managers from One Director to Another Director",
    value: "transfer-managers-from-one-director-to-another-director",
    transferringRole: ROlES.MANAGER,
    supervisorRole: ROlES.DIRECTOR,
  },
  // {
  //   name: "Transfer Associates from Promoted Manager to Head",
  //   value: "transfer-associates-from-promoted-manager-to-another-head",
  // },
];

export const getTeamLength = (user, role) => {
  const updateFields = {
    NATIONAL_DIRECTOR: "provincialDirectorIds",
    PROVINCIAL_DIRECTOR: "managerIds",
    DIRECTOR: "managerIds",
    MANAGER: "associateIds",
    HEAD: "associateIds",
    ASSOCIATE: null,
  };

  const fieldName = updateFields[role];

  if (fieldName && user[fieldName]) {
    return user[fieldName].length;
  }

  return 0;
};

export const CODE_TYPES = {
  Promo_Code: "Promo Code",
  CA_Code: "CA Code",
};

/**
 * Checks if a user is active based on their status
 * @param user - The user object to check
 * @returns boolean indicating whether the user is active
 */
export const isUserActive = (user: any): boolean => {
  // Handle null or undefined input
  if (!user) return false;

  // Check if user has an active status property
  if (typeof user === "object" && "isActive" in user) {
    return user.isActive === true;
  }

  // Default to false if no clear active status is found
  return false;
};

export const TableTop = ({
  searchQuery,
  handleSearchQueryChange,
  setAllUsers,
  adminUsers,
  reportToUsers,
  allAdminUsers,
  allCommissionUsers,
  label,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
      <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
        <ButtonFill
          handleClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add new user
        </ButtonFill>
      </div>
      <CommissionUserForm
        mode="add"
        active={isModalOpen}
        setModal={setIsModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          setIsModalOpen(false);
        }}
        setAllUsers={setAllUsers}
        adminUsers={adminUsers}
        allAdminUsers={allAdminUsers}
        reportToUsers={reportToUsers}
        allCommissionUsers={allCommissionUsers}
        label={label}
      />
    </div>
  );
};

export const MAX_COMMISSION = 15;

export const SCHEME_TYPES = {
  REFERRAL: "REFERRAL",
  ORDER: "ORDER",
};

export const SCHEME_TYPES_OPTION = ["REFERRAL", "ORDER"];

export const getActiveData = (items: any) => {
  return items?.filter((item: any) => item?.isActive);
};

export const allowAccess = (loggedInUser: any) => {
  let access = false;

  const allowedUsers = [
    "durresameenkhan01@gmail.com",
    "ddd@gmail.com",
    "dddd@gmail.com",
    "hibaaqasim20@gmail.com",
    "r.ahmerkhan18@gmail.com",
    "rizwanlaiba621@gmail.com",
    // "ablal@gmail.com",
    "sms@gmail.com",
  ];

  // if (requestedRole === ROlES.DIRECTOR) {
  //   access = true;
  // }
  // else

  const HasPerm = (user: any, perm: string[]): boolean => {
    // Check if user and user.admin are defined
    if (!user || !user?.admin || !user?.isadminuser) {
      return false;
    }

    // Check if pagepermissions is defined and is an array
    if (
      !user?.admin?.pagepermissions ||
      !Array.isArray(user?.admin?.pagepermissions)
    ) {
      return false;
    }

    return perm.some((p) =>
      user?.admin?.pagepermissions?.some(
        (pp) => pp.toLowerCase() === p.toLowerCase()
      )
    );
  };

  const permissionAllowed = HasPerm(loggedInUser, ["Commission System"]);

  if (allowedUsers.includes(loggedInUser?.username) || permissionAllowed) {
    access = true;
    // console.log("Access True");
  }
  return access;
};

export const GetTime = (date: Date) => {
  const d = new Date(date);
  return d.toLocaleTimeString();
};

export const getOverviewStats = (user, role) => {
  // console.log("getOverviewStats", user);
  const defaultStats = {
    totalAssociates: 0,
    mySales: 0,
    myTodaySales: 0,
    todaysAssociatesSales: 0,
    totalReferrals: 0,
    thisMonthSales: 0,
    lastMonthSales: 0,
    totalAssociatesSales: 0,
    myTotalReferrals: 0,
  };

  try {
    // if (!user || role !== "MANAGER") {
    //   return defaultStats;
    // }

    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    const orders = Array.isArray(user.orders) ? user.orders : [];
    const associateIds = [];

    // Safely handle different input types
    const processUser = (user: any) => {
      // Ensure user is an object and has the required properties
      if (typeof user === "object" && user !== null) {
        // Check if user is active before adding
        if (isUserActive(user)) {
          associateIds.push(user._id);
        } else {
        }
      } else {
        console.log("Invalid user object:", user);
      }
    };

    // Process users from different potential sources

    if (Array.isArray(user.associateIds)) {
      user.associateIds.forEach(processUser);
    } else if (
      typeof user.associateIds === "object" &&
      user.associateIds !== null
    ) {
      // Handle case where users might be an object
      Object.values(user.associateIds).forEach(processUser);
    }

    const stats = {
      // 1. Total Associates Count (only active users)
      totalAssociates: associateIds.length,

      // 2. My Sales (count of my personal orders - all time)
      mySales: orders.filter((order) => order?.personalCoupon).length,

      // 3. My Today's Sales Count
      myTodaySales: orders.filter((order) => {
        if (!order?.personalCoupon || !order.createdAt) return false;
        const orderDate = new Date(order.createdAt);
        return orderDate >= startOfToday;
      }).length,

      // 4. Today's Associates Sales Count
      todaysAssociatesSales: orders.filter((order) => {
        if (order?.personalCoupon || !order.createdAt) return false;
        const orderDate = new Date(order.createdAt);
        return orderDate >= startOfToday;
      }).length,

      // 5. Total Referrals Count
      totalReferrals: (() => {
        const referralsCount = associateIds.reduce((total, associateId) => {
          // Find the associate in the original user data
          const associate = user.associateIds.find(
            (a: any) => a._id === associateId || a.userId === associateId
          );

          if (!associate?.targets) {
            return total;
          }

          const associateReferrals = associate.targets.reduce((sum, target) => {
            if (
              target?.schemeType !== "REFERRAL" ||
              !Array.isArray(target.userIds)
            ) {
              return sum;
            }

            return sum + target.userIds.length;
          }, 0);

          return total + associateReferrals;
        }, 0);

        return referralsCount;
      })(),

      // 6. This Month's Total Orders Count
      thisMonthSales: orders.filter((order) => {
        if (!order?.createdAt) return false;
        const orderDate = new Date(order.createdAt);
        return (
          orderDate.getMonth() === currentMonth &&
          orderDate.getFullYear() === currentYear
        );
      }).length,

      // 7. Last Month's Total Orders Count
      lastMonthSales: orders.filter((order) => {
        if (!order?.createdAt) return false;
        const orderDate = new Date(order.createdAt);
        return (
          orderDate.getMonth() === lastMonth &&
          orderDate.getFullYear() === lastMonthYear
        );
      }).length,

      // 5. Total Associates Sales (all time associate orders)
      totalAssociatesSales: orders.filter((order) => !order?.personalCoupon)
        .length,

      // Total Referrals (unique user IDs in REFERRAL targets)
      myTotalReferrals: associateIds.reduce((total, associateId) => {
        if (!associateId?.targets) return total;
        return (
          total +
          associateId.targets.reduce((sum, target) => {
            if (
              target?.schemeType !== "REFERRAL" ||
              !Array.isArray(target.userIds)
            )
              return sum;
            return sum + target.userIds.length;
          }, 0)
        );
      }, 0),
    };

    // No need for toFixed() since we're counting orders now
    return stats;
  } catch (error) {
    return defaultStats;
  }
};
// Example usage:
/*
const stats = getOverviewStats(user, "MANAGER");
if (stats.error) {
  console.error(stats.error);
} else {
  console.log(stats.stats);
}
*/

export const REIMBURSEMENT_PAGE_ACCESS_EMAILS = [
  "durresameenkhan01@gmail.com",
  "ddd@gmail.com",
  "dddd@gmail.com",
  "rizwanlaiba621@gmail.com",
  "ablal@gmail.com",
  "sms@gmail.com",
];
