import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message, Modal } from "antd";
import CustomButton from "shared-components/form-fields/custom-button";
import { UserContext } from "state-management/user-context";
import EmployeeModal from "./modal";
import EmployeeTable from "./table";
import PaymentView from "./view-modal";
import Loading from "shared-components/ui/Loading";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.array().required("Type is required"),
  bankDetails: Yup.array().of(
    Yup.object().shape({
      accountNumber: Yup.string().required("Account Number is required"),
      accountHolderName: Yup.string().required(
        "Account Holder Name is required"
      ),
      bankName: Yup.string().required("Bank Name is required"),
    })
  ),
  phoneNumber: Yup.string().optional(),
  email: Yup.string().email("Invalid email").optional(),
  departments: Yup.array().required("Department is required"),
  positions: Yup.array().required("Position is required"),
});

const Employees = () => {
  const [addPopup, setAddPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [viewModel, setViewModal] = useState(false);
  const [currentEmployeeData, setCurrentEmployeeData] = useState([]);
  const [senderBankAccounts, setSenderBankAccounts] = useState([]);
  const { user } = useContext(UserContext);

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllEmployees");

      // Reset states by default
      setEmployeeData([]);
      setSenderBankAccounts([]);

      if (res?.status === 200 && res?.data) {
        // Handle employees data
        const employees = res.data?.employees || res.data || [];
        if (Array.isArray(employees) && employees.length > 0) {
          const updatedRow = employees.map((item) => ({
            ...item,
            key: item._id,
            id: item._id,
          }));
          setEmployeeData(updatedRow);
        }

        // Handle bank accounts data
        const bankAccounts = res.data?.bankAccounts || [];
        if (Array.isArray(bankAccounts) && bankAccounts.length > 0) {
          const updatedBankAccounts = bankAccounts.map((item: any) => ({
            ...item,
            label: `(${item.bankName})-(${item.holderName})-(${item.accountNumber})`,
            value: item.accountNumber,
          }));
          setSenderBankAccounts(updatedBankAccounts);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllDepartments");
      if (res.status === 200) {
        if (res.data.length > 0) {
          const updatedRow = res?.data?.map((item) => {
            return {
              ...item,
              value: item._id,
              label: item.name,
            };
          });
          setDepartmentData(updatedRow);
        } else {
          setDepartmentData([]);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchDepartments();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      editEmployeeId: "",
      type: "",
      email: "",
      salary: "",
      salaryHistory: [
        {
          salary: 0,
        },
      ],
      bankDetails: [
        {
          accountNumber: "",
          accountHolderName: "",
          bankName: "",
        },
      ],
      departments: null,
      positions: [],
      categories: [],
      contactInformation: "",
      addedBy: user?.fullname,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Add your submission logic here
      const payload = {
        ...values,
        addedBy: user?.fullname,
      };
      try {
        const { editEmployeeId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updateEmployee", payload, [editEmployeeId]);
        } else {
          res = await apiRequest("addEmployee", payload);
        }
        console.log("res: " + res);
        if (res.data?.isSuccess) {
          fetchEmployees();
          message.success(res?.data?.message);
          setAddPopup(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const { setValues } = formik;

  const handleEdit = (record) => {
    const { id } = record;
    setValues({
      ...record,
      editEmployeeId: id,
    });
    setIsEditMode(true);
    setAddPopup(true);
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this employee?',
      icon: <ExclamationCircleOutlined />,
      content: `Employee Name: ${record.name}`,
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: async () => {
        try {
          const res = await apiRequest("deleteEmployee", {}, [record.id]);
          if (res.data?.isSuccess) {
            fetchEmployees();
            message.success(res?.data?.message || "Employee deleted successfully");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || error?.message);
        }
      },
      onCancel() {
        // Optional: Add any cancel logic if needed
      },
    });
  };

  return (
    <div>
      <div className="">
        <div className={`flex justify-end items-end  gap-4`}>
          <CustomButton
            text="Add New Employee"
            onClick={() => {
              setAddPopup(true);
              setIsEditMode(false);
              formik.resetForm();
            }}
            icon={<PlusOutlined />}
          />
        </div>
        <div>
          {EmployeeData.length > 0 && (
            <EmployeeTable
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              setViewModal={setViewModal}
              isLoading={isLoading}
              EmployeeData={EmployeeData}
              setCurrentEmployeeData={setCurrentEmployeeData}
              senderBankAccounts={senderBankAccounts}
            />
          )}
        </div>
      </div>
      <EmployeeModal
        addPopup={addPopup}
        isEditMode={isEditMode}
        setAddPopup={setAddPopup}
        formik={formik}
        departmentData={departmentData}
        loading={isLoading}
      />
      <PaymentView
        isLoading={isLoading}
        viewModel={viewModel}
        setViewModal={setViewModal}
        data={currentEmployeeData}
      />
    </div>
  );
};

export default Employees;
