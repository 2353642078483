import { Button, message } from "antd";
import React, { useContext } from "react";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon, EyeIcon, ManageIcon, DeleteIcon } from "shared-components/ui/Icons";
import PaymentModal from "../payments/modal";
import { useFormik } from "formik";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { paymentValidationSchema } from "../payments/schema";
import { UserContext } from "state-management/user-context";
import { paymentsInitialValues } from "../payments/initialValues";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { useNavigate } from "react-router-dom";

const EmployeeTable = ({
  handleEdit,
  handleDelete,
  isLoading,
  EmployeeData,
  setViewModal,
  setCurrentEmployeeData,
  senderBankAccounts,
}) => {
  const navigate = useNavigate();
  const [openAddPaymentModal, setOpenAddPaymentModal] = React.useState(false);
  const [selectedemployeeData, setSelectedEmployeeData] = React.useState(null);
  const { user } = useContext(UserContext);
  const formik = useFormik({
    initialValues: paymentsInitialValues,
   validationSchema:paymentValidationSchema,
   onSubmit: async(values, {resetForm}) => {
    const screensShotBase64 = values?.screenshot &&  await identifyAndConvertNewImagesToBase64(values?.screenshot);
    const payload = {
      ...values,
      paymentBreakdown:values?.categoryAmountList,
      addedBy: user?.fullname,
      screenshot: screensShotBase64?.[0],
    };
    try {
      let res= await apiRequest("addPayment", payload);
      if (res.data?.isSuccess) {
        message.success(res?.data?.message);
        setOpenAddPaymentModal(false);
        resetForm();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  },
  });
  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Salary (PKR)",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Departments",
    //   dataIndex: "departments",
    //   key: "departments",
    // },
    {
      title: "Positions",
      dataIndex: "positions",
      key: "positions",
    },
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div
            className="text-xl flex gap-4"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
          <div
            className="text-xl flex gap-4"
            onClick={() => {
              setCurrentEmployeeData(record);
              setViewModal(true);
            }}
          >
            <EyeIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
          <div
            className="text-xl flex gap-4"
            onClick={() => {
              navigate(`/manage/payment-history/internal/${record?.id}`)
            }}
          >
            <ManageIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
          <div
            className="text-xl flex gap-4"
            onClick={() => handleDelete(record)}
          >
            <DeleteIcon color="text-red-600" size="w-6 h-6"/>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <GlobalTable loading={isLoading} columns={columns} data={EmployeeData}             size="large"
 />
 <PaymentModal  selectedemployeeData={selectedemployeeData}  isEmployee={true}  openModal={openAddPaymentModal}
  setOpenModal={setOpenAddPaymentModal} formik={formik} bankAccountsData={senderBankAccounts} />
    </div>
  );
};

export default EmployeeTable;
