import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Select } from "antd";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { categoryValues } from "constants/constantvalues";
import { X } from "@mui/icons-material";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "state-management/user-context";
import { DeleteIcon, EditIcon, EyeIcon } from "shared-components/ui/Icons";

export const PaymentModal = ({
  ShowModel,
  setShowModel,
  isEditMode,
  selectedPayment,
  fetchInternalPayments,
  setModalType,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  isEditMode: boolean;
  selectedPayment: any;
  fetchInternalPayments: () => void;
  setModalType: (value: string) => void;
}) => {
  const [employees, setEmployees] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [departments, setDepartments] = useState([]);

  const { user } = useContext(UserContext);

  const GetEmployees = async () => {
    const res = await apiRequest("getAllEmployees");
    if (res.data?.isSuccess) {
      setEmployees(res.data?.employees);
    }
    const res2 = await apiRequest("getBankAccounts");
    if (res2.data?.isSuccess) {
      setBankAccounts(res2.data?.bankAccounts);
      setDepartments(res2.data?.departments);
    }
  };

  useEffect(() => {
    GetEmployees();
  }, []);

  interface BreakDownAmounts {
    category: string;
    amount: number;
    bonus: number;
    bankAccount: string;
    department: string;
  }

  const [formData, setFormData] = useState<{
    employeeId: string;
    type: string;
    breakdownAmounts: BreakDownAmounts[];
    paidByOurAccounts: string[];
    addedBy: string;
    description: string;
    modifiedBy: string;
    screenshot: string;
    payment_date: Date;
    createdAt: Date;
  }>({
    employeeId: "",
    type: "",
    breakdownAmounts: [],
    paidByOurAccounts: [],
    description: "",
    addedBy: "",
    modifiedBy: "",
    screenshot: "",
    payment_date: new Date(),
    createdAt: new Date(),
  });

  useEffect(() => {
    if (selectedPayment && isEditMode) {
      setFormData({
        employeeId: selectedPayment?.employee._id,
        type: selectedPayment?.type,
        breakdownAmounts: selectedPayment?.breakdownAmounts.map((item) => ({
          category: item.category,
          amount: item.amount,
          bonus: item.bonus,
          bankAccount: item.bankAccount,
          department: item.department,
        })),
        paidByOurAccounts: selectedPayment?.paidByOurAccounts,
        createdAt: new Date(selectedPayment?.createdAt),
        addedBy: selectedPayment?.addedBy,
        modifiedBy: selectedPayment?.modifiedBy,
        screenshot: selectedPayment?.screenshot,
        description: selectedPayment?.description,
        payment_date: new Date(selectedPayment?.payment_date || new Date()),
      });
    }
  }, [selectedPayment, isEditMode]);

  const handleSubmit = async () => {
    try {
      //iterate through breakdown, if any breakdown bonus is empty then make it 0
      formData.breakdownAmounts.forEach((item) => {
        if (
          !item.bonus ||
          item.bonus === 0 ||
          item.bonus < 0 ||
          isNaN(Number(item.bonus)) ||
          item.bonus === undefined
        ) {
          item.bonus = 0;
        }
      });
      //if no screenshot is uploaded then make it empty
      if (
        !formData.screenshot &&
        selectedPayment?.screenshot &&
        selectedPayment?.screenshot !== "" &&
        isEditMode
      ) {
        formData.screenshot = selectedPayment?.screenshot;
      }

      //validate form data
      if (formData.breakdownAmounts.length === 0) {
        toast.error("Please add at least one breakdown amount");
        return;
      }
      //make sure all breakdown amounts are numbers and not negative
      formData.breakdownAmounts.forEach((item) => {
        if (
          isNaN(Number(item.amount)) ||
          isNaN(Number(item.bonus)) ||
          Number(item.amount) < 0 ||
          Number(item.bonus) < 0
        ) {
          toast.error("Please enter valid numbers for breakdown amounts");
          return;
        }
      });
      //iterate through breakdown amounts and make sure every field is filled
      formData.breakdownAmounts.forEach((item) => {
        if (
          !item.category ||
          !item.amount ||
          !item.bankAccount ||
          !item.department
        ) {
          toast.error("Please fill all fields for breakdown amounts");
          return;
        }
      });
      //make sure employeeId and bankAccount are not empty
      if (!formData.employeeId || !formData.type) {
        toast.error("Please select an employee and a payment type");
        return;
      }
      //make sure total amount is not negative
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + (Number(item.amount) + Number(item.bonus)),
          0
        ) < 0
      ) {
        toast.error("Total amount cannot be negative");
        return;
      }
      //make sure total amount is not zero
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + (Number(item.amount) + Number(item.bonus)),
          0
        ) === 0
      ) {
        toast.error("Total amount cannot be zero");
        return;
      }
      //make sure user is logged in
      if (!user?.username) {
        toast.error("Please login to continue");
        return;
      }
      //make sure createdAt is not in the future
      if (formData.createdAt > new Date()) {
        toast.error("Created at cannot be in the future");
        return;
      }
      //make sure paidByOurAccounts is not empty
      if (formData.paidByOurAccounts.length === 0) {
        toast.error("Please select at least one paid by our account");
        return;
      }
      //make sure screenshot is not empty
      if (!formData.screenshot) {
        toast.error("Please upload a screenshot");
        return;
      }

      //make sure payment_date is not in the future
      if (formData.payment_date > new Date()) {
        toast.error("Payment date cannot be in the future");
        return;
      }

      // Check if in edit mode and no new screenshot is uploaded
      if (isEditMode && !formData.screenshot) {
        formData.screenshot = selectedPayment?.screenshot; // Retain the existing screenshot
      }

      const payload = {
        ...formData,
        totalAmount: formData.breakdownAmounts.reduce(
          (sum, item) => sum + (Number(item.amount) + Number(item.bonus)),
          0
        ),
        addedBy: isEditMode ? selectedPayment?.addedBy : user?.username,
        modifiedBy: isEditMode ? selectedPayment?.modifiedBy : user?.username,
      };

      const res = await apiHandler({
        data: payload,
        name: isEditMode ? "updateInternalPayment" : "createInternalPayment",
        params: isEditMode ? [`${selectedPayment?._id}`] : [],
      });

      if (res.data?.isSuccess) {
        toast.success("Payment submitted successfully");

        setShowModel(false);
        setModalType("add");
        // Reset form
        setFormData({
          employeeId: "",
          type: "",
          breakdownAmounts: [],
          paidByOurAccounts: [],
          addedBy: "",
          description: "",
          modifiedBy: "",
          screenshot: "",
          payment_date: new Date(),
          createdAt: new Date(),
        });
        fetchInternalPayments();
        window.location.reload();
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      toast.error("Payment submission error");
    }
  };

  const convertToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-4xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {isEditMode ? "Update" : "Add New"} Payment
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setFormData({
                  employeeId: "",
                  type: "",
                  breakdownAmounts: [],
                  paidByOurAccounts: [],
                  addedBy: "",
                  modifiedBy: "",
                  createdAt: new Date(),
                  screenshot: "",
                  description: "",
                  payment_date: new Date(),
                });
                setShowModel(false);
                setModalType("add");
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/*SELECT EMPLOYEE*/}
          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-2">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="employeeId">Employee</label>
                <Select
                  disabled={isEditMode}
                  showSearch
                  placeholder="Select an employee"
                  options={employees.map((employee) => ({
                    label: employee.name,
                    value: employee._id,
                  }))}
                  onChange={(value) => {
                    setFormData({ ...formData, employeeId: value });
                  }}
                  className="w-full h-10 border border-gray-300 font-rubik"
                  bordered={false}
                  value={formData.employeeId}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </div>
            </div>

            <label htmlFor="type">Payment Type</label>
            <Select
              placeholder="Select payment type"
              options={[
                { label: "Salary", value: "SALARY" },
                { label: "Per Task", value: "PER_TASK" },
                { label: "Advance", value: "ADVANCE" },
                { label: "Bonus", value: "BONUS" },
                { label: "Commission", value: "COMMISSION" },
                { label: "Other", value: "OTHER" },
              ]}
              onChange={(value) => setFormData({ ...formData, type: value })}
              className="w-full h-10 border border-gray-300 font-rubik"
              bordered={false}
              value={formData.type}
            />

            {/* Breakdown Amounts Section */}
            <div className="mt-4">
              <label className="block mb-2">Payment Breakdown</label>
              {formData.breakdownAmounts.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg"
                >
                  <CustomDropDown
                    options={categoryValues.map((category) => category.value)}
                    onSelect={(value) => {
                      const newBreakdown = [...formData.breakdownAmounts];
                      newBreakdown[index].category = value;
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    value={item.category}
                    label="Category"
                    width="w-full"
                  />
                  <div className="flex flex-col gap-2">
                    <CustomDropDown
                      options={
                        employees
                          .find(
                            (employee) => employee._id === formData.employeeId
                          )
                          ?.bankDetails.map(
                            (bankAccount) =>
                              `${bankAccount?.accountHolderName} | ${bankAccount?.accountNumber} | ${bankAccount?.bankName}`
                          ) || []
                      }
                      onSelect={(value) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].bankAccount = value;
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                      value={item.bankAccount}
                      label="Bank Account"
                      width="w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="border p-2 rounded w-full bg-transparent"
                      value={item.amount || ""}
                      onChange={(e) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].amount =
                          e.target.value === "" ? 0 : Number(e.target.value);
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <label htmlFor="bonus">Bonus</label>
                    <input
                      type="number"
                      placeholder="Bonus"
                      className="border p-2 rounded w-full bg-transparent"
                      value={item.bonus || ""}
                      onChange={(e) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].bonus =
                          e.target.value === "" ? 0 : Number(e.target.value);
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <CustomDropDown
                      options={departments.map((department) => department.name)}
                      onSelect={(value) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].department = value;
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                      label="Department"
                      width="w-full"
                      value={item.department}
                    />
                  </div>

                  <X
                    onClick={() => {
                      const newBreakdown = formData.breakdownAmounts.filter(
                        (_, i) => i !== index
                      );
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                  />
                </div>
              ))}
              <ButtonFill
                handleClick={() => {
                  setFormData({
                    ...formData,
                    breakdownAmounts: [
                      ...formData.breakdownAmounts,
                      {
                        category: "",
                        amount: 0,
                        bonus: 0,
                        bankAccount: "",
                        department: "",
                      },
                    ],
                  });
                }}
              >
                Add Breakdown
              </ButtonFill>
            </div>

            {/* Paid By Our Accounts Section */}
            <div className="mt-4">
              <label className="block mb-2">Paid By Our Accounts</label>
              <Select
                placeholder="Select paid by our accounts"
                options={bankAccounts.map((bankAccount) => ({
                  label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                  value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                }))}
                onChange={(value) => {
                  setFormData({ ...formData, paidByOurAccounts: value });
                }}
                className="w-full h-10 border border-gray-300 font-rubik"
                bordered={false}
                value={formData.paidByOurAccounts}
                mode="multiple"
              />
            </div>

            {/* Added By Section */}

            {/* Created At Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              <div className="mt-4">
                <label className="block mb-2">Expense Date</label>
                <input
                  type="date"
                  placeholder="Created At"
                  className="border p-2 rounded"
                  value={formData.createdAt.toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      createdAt: new Date(e.target.value),
                    })
                  }
                />
              </div>

              <div className="mt-4">
                <label className="block mb-2">Payment Date</label>
                <input
                  type="date"
                  placeholder="Payment Date"
                  className="border p-2 rounded"
                  value={formData.payment_date.toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      payment_date: new Date(e.target.value),
                    })
                  }
                />
              </div>

              <div className="mt-4">
                <label className="block mb-2">Screenshot</label>
                <input
                  type="file"
                  className="border p-2 rounded"
                  accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    //upload in base64
                    const file = e.target.files?.[0];
                    if (file) {
                      const base64 = await convertToBase64(file);
                      setFormData({
                        ...formData,
                        screenshot: base64 as string,
                      });
                    }
                  }}
                />
                {formData.screenshot && (
                  <img
                    src={formData.screenshot}
                    alt="Screenshot"
                    className="w-24 h-24 object-fit border mt-2 rounded"
                  />
                )}
              </div>
            </div>

            {/* Description Section */}
            <div className="mt-4 w-full">
              <label className="block mb-2">Description</label>
              <input
                type="text"
                placeholder="Description"
                className="border p-2 rounded w-full"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </div>

            {/* Screenshot Section */}

            {/* Submit Button */}
            <div className="mt-4 flex justify-end">
              <ButtonFill
                handleClick={handleSubmit}
                disabled={!formData.employeeId || !formData.type}
              >
                {isEditMode ? "Update" : "Submit"} Payment
              </ButtonFill>
            </div>
            <Toaster />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewPaymentModal = ({
  ShowModel,
  setShowModel,
  selectedPayment,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  selectedPayment: any;
}) => {
  const GetDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div
      id="viewPaymentModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-6xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              View Payment
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setShowModel(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/* TODO: Add Payment Details */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <b>Employee:</b> {selectedPayment?.employee?.name}
            </div>
            <div className="flex flex-row gap-2">
              <b>Type:</b> {selectedPayment?.type}
            </div>
            <div className="flex flex-row gap-2">
              <b>Amount Paid:</b> {selectedPayment?.totalAmount}
            </div>
            <div className="flex flex-col gap-2">
              <b>Paid By Our Accounts:</b>
              <div className="flex flex-row gap-2">
                {selectedPayment?.paidByOurAccounts.map(
                  (account: any, index: number) => (
                    <div key={index}>
                      <b>{index + 1}.</b> {account}
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <b>Breakdown Amounts:</b>
              <div className="flex flex-row gap-2 w-full">
                <table className="w-full">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Category
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Amount
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Bonus
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Department
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Bank Account
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPayment?.breakdownAmounts.map(
                      (item: any, index: number) => (
                        <tr key={index} className="border-b">
                          <td className="px-4 py-3 text-center">{index + 1}</td>
                          <td className="px-4 py-3 text-center">
                            {item?.category}
                          </td>
                          <td className="px-4 py-3 text-center">
                            {item?.amount}
                          </td>
                          <td className="px-4 py-3 text-center">
                            {item?.bonus}
                          </td>
                          <td className="px-4 py-3 text-center">
                            {item?.department}
                          </td>
                          <td className="px-4 py-3 text-center">
                            <table className="w-full flex justify-center flex-col items-center">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-4 py-2 text-xs">
                                    Holder Name
                                  </th>
                                  <th scope="col" className="px-4 py-2 text-xs">
                                    Account Number
                                  </th>
                                  <th scope="col" className="px-4 py-2 text-xs">
                                    Bank Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="w-full">
                                  <td className="px-4 py-3 text-center text-sm">
                                    {item?.bankAccount.split(" | ")[0]}
                                  </td>
                                  <td className="px-4 py-3 text-center text-sm">
                                    {item?.bankAccount.split(" | ")[1]}
                                  </td>
                                  <td className="px-4 py-3 text-center text-sm">
                                    {item?.bankAccount.split(" | ")[2]}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Description Section */}
            <div className="mt-4 w-full">
              <label className="block mb-2">Description</label>
              <div className="border p-2 rounded w-full">
                {selectedPayment?.description}
              </div>
            </div>

            <div className="flex flex-row gap-2 justify-between">
              <div className="flex justify-start gap-2">
                <b>Added By:</b> {selectedPayment?.addedBy}
              </div>
              <div className="flex justify-end gap-2">
                <b>Expense Date:</b> {GetDate(selectedPayment?.createdAt)}
              </div>
              <div className="flex justify-end gap-2">
                <b>Payment Date:</b> {GetDate(selectedPayment?.payment_date)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InternalPaymentsTable = ({
  internalPayments,
  setOpenAddPaymentModal,
  setModalType,
  setSelectedPayment,
  setOpenViewPaymentModal,
  showEdit = true,
  GetDate,
  fetchInternalPayments,
}: {
  internalPayments: any[];
  setOpenAddPaymentModal: (value: boolean) => void;
  setModalType: (value: string) => void;
  setSelectedPayment: (value: any) => void;
  setOpenViewPaymentModal: (value: boolean) => void;
  GetDate: (date: string) => string;
  fetchInternalPayments: () => void;
  showEdit?: boolean;
}) => {
  const DeleteInternalPayment = async (id: string) => {
    try {
      const response = await apiHandler({
        name: "deleteInternalPayment",
        params: [id],
      });
      if (response.data.isSuccess) {
        fetchInternalPayments();
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete internal payment");
      console.error(error);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(internalPayments);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    field: "",
    direction: "asc"
  });

  // New filter states
  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [amountFilterType, setAmountFilterType] = useState("exact");
  const [amountStart, setAmountStart] = useState("");
  const [amountEnd, setAmountEnd] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Extract unique payment types and departments from data
  const paymentTypes = useMemo(() => {
    const types = new Set(internalPayments.map((payment) => payment.type));
    return Array.from(types).filter((type) => type);
  }, [internalPayments]);

  const departments = useMemo(() => {
    const depts = new Set(
      internalPayments
        .filter((payment) => payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts))
        .flatMap((payment) =>
          payment.breakdownAmounts
            .filter((breakdown) => breakdown?.department)
            .map((breakdown) => breakdown.department)
        )
    );
    return Array.from(depts).filter((dept) => dept);
  }, [internalPayments]);

  const categories = useMemo(() => {
    const cats = new Set(
      internalPayments
        .filter((payment) => payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts))
        .flatMap((payment) =>
          payment.breakdownAmounts
            .filter((breakdown) => breakdown?.category)
            .map((breakdown) => breakdown.category)
        )
    );
    return Array.from(cats).filter((cat) => cat);
  }, [internalPayments]);

  const handleFilters = () => {
    let filtered = [...internalPayments];

    // Filter by expense date range
    if (expenseStartDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.createdAt) >= new Date(expenseStartDate)
      );
    }
    if (expenseEndDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.createdAt) <= new Date(expenseEndDate)
      );
    }

    // Filter by payment date range
    if (paymentStartDate) {
      filtered = filtered.filter(
        (payment) =>
          new Date(payment.payment_date) >= new Date(paymentStartDate)
      );
    }
    if (paymentEndDate) {
      filtered = filtered.filter(
        (payment) => new Date(payment.payment_date) <= new Date(paymentEndDate)
      );
    }

    // Filter by employee name
    if (employeeName) {
      filtered = filtered.filter((payment) =>
        payment?.employee?.name
          .toLowerCase()
          .includes(employeeName.toLowerCase())
      );
    }

    // Enhanced amount filtering logic
    if (amountFilterType === "exact" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount === Number(amountStart)
      );
    } else if (amountFilterType === "less" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount < Number(amountStart)
      );
    } else if (amountFilterType === "greater" && amountStart) {
      filtered = filtered.filter(
        (payment) => payment.totalAmount > Number(amountStart)
      );
    } else if (amountFilterType === "range" && amountStart && amountEnd) {
      filtered = filtered.filter(
        (payment) =>
          payment.totalAmount >= Number(amountStart) &&
          payment.totalAmount <= Number(amountEnd)
      );
    }

    // Filter by description
    if (descriptionSearch) {
      filtered = filtered.filter((payment) =>
        payment?.description
          ?.toLowerCase()
          .includes(descriptionSearch.toLowerCase())
      );
    }

    // Filter by payment type
    if (selectedPaymentType) {
      filtered = filtered.filter(
        (payment) => payment.type === selectedPaymentType
      );
    }

    // Filter by department
    if (selectedDepartment) {
      filtered = filtered.filter((payment) =>
        payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
          ? payment.breakdownAmounts.some(
              (breakdown) => breakdown?.department === selectedDepartment
            )
          : false
      );
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter((payment) =>
        payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
          ? payment.breakdownAmounts.some(
              (breakdown) => breakdown?.category === selectedCategory
            )
          : false
      );
    }

    // Apply sorting if selected
    if (sortConfig.field) {
      filtered.sort((a, b) => {
        let aValue, bValue;
        
        switch (sortConfig.field) {
          case 'amount':
            aValue = a.totalAmount || 0;
            bValue = b.totalAmount || 0;
            break;
          case 'employeeName':
            aValue = (a.employee?.name || '').toLowerCase().trim();
            bValue = (b.employee?.name || '').toLowerCase().trim();
            return sortConfig.direction === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'paymentDate':
            aValue = new Date(a.payment_date).getTime();
            bValue = new Date(b.payment_date).getTime();
            break;
          case 'expenseDate':
            aValue = new Date(a.createdAt).getTime();
            bValue = new Date(b.createdAt).getTime();
            break;
          default:
            return 0;
        }

        if (sortConfig.direction === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setExpenseStartDate("");
    setExpenseEndDate("");
    setPaymentStartDate("");
    setPaymentEndDate("");
    setEmployeeName("");
    setAmountFilterType("exact");
    setAmountStart("");
    setAmountEnd("");
    setDescriptionSearch("");
    setSelectedPaymentType("");
    setSelectedDepartment("");
    setSelectedCategory("");
    setSortConfig({ field: "", direction: "asc" });
    setFilteredData(internalPayments);
    setCurrentPage(1);
  };

  const getPageData = () => {
    const startIndex = (currentPage - 1) * 20;
    const endIndex = startIndex + 20;
    return filteredData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        {/* Expense Date Filters */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Expense Date Range</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={expenseStartDate}
              onChange={(e) => setExpenseStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={expenseEndDate}
              onChange={(e) => setExpenseEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div>

        {/* Payment Date Filters */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Payment Date Range</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={paymentStartDate}
              onChange={(e) => setPaymentStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={paymentEndDate}
              onChange={(e) => setPaymentEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div>

        {/* Employee Name Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Employee Name</label>
          <input
            type="text"
            placeholder="Search employee..."
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Amount Search with Enhanced Options */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Amount</label>
          <div className="flex gap-2">
            <select
              value={amountFilterType}
              onChange={(e) => {
                setAmountFilterType(e.target.value);
                setAmountStart("");
                setAmountEnd("");
              }}
              className="border p-2 rounded bg-transparent w-1/3"
            >
              <option value="exact">Exact</option>
              <option value="less">Less than</option>
              <option value="greater">Greater than</option>
              <option value="range">Range</option>
            </select>
            <div className="flex gap-2 w-2/3">
              {amountFilterType === "range" ? (
                <>
                  <input
                    type="number"
                    placeholder="From"
                    value={amountStart}
                    onChange={(e) => setAmountStart(e.target.value)}
                    className="border p-2 rounded w-1/2 bg-transparent"
                  />
                  <input
                    type="number"
                    placeholder="To"
                    value={amountEnd}
                    onChange={(e) => setAmountEnd(e.target.value)}
                    className="border p-2 rounded w-1/2 bg-transparent"
                  />
                </>
              ) : (
                <input
                  type="number"
                  placeholder={
                    amountFilterType === "exact"
                      ? "Enter amount"
                      : amountFilterType === "less"
                      ? "Less than"
                      : "Greater than"
                  }
                  value={amountStart}
                  onChange={(e) => setAmountStart(e.target.value)}
                  className="border p-2 rounded w-full bg-transparent"
                />
              )}
            </div>
          </div>
        </div>

        {/* Description Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Description</label>
          <input
            type="text"
            placeholder="Search description..."
            value={descriptionSearch}
            onChange={(e) => setDescriptionSearch(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Payment Type Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Payment Type</label>
          <select
            value={selectedPaymentType}
            onChange={(e) => setSelectedPaymentType(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Types</option>
            {paymentTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {/* Department Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Department</label>
          <select
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Departments</option>
            {departments.map((department) => (
              <option key={department} value={department}>
                {department}
              </option>
            ))}
          </select>
        </div>

        {/* Category Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Category</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Sort Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Sort By</label>
          <div className="flex gap-2">
            <select
              value={sortConfig.field}
              onChange={(e) => setSortConfig(prev => ({ ...prev, field: e.target.value }))}
              className="border p-2 rounded w-2/3 bg-transparent"
            >
              <option value="">No Sort</option>
              <option value="amount">Amount</option>
              <option value="employeeName">Name</option>
              <option value="paymentDate">Pay Date</option>
              <option value="expenseDate">Exp Date</option>
            </select>
            <select
              value={sortConfig.direction}
              onChange={(e) => setSortConfig(prev => ({ ...prev, direction: e.target.value }))}
              className="border p-2 rounded w-1/3 bg-transparent"
            >
              <option value="asc">Asc</option>
              <option value="desc">Desc</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 mb-4">
        <button
          className="bg-primary text-white p-2 rounded"
          onClick={handleFilters}
        >
          Apply Filters
        </button>
        <button
          className="bg-gray-500 text-white p-2 rounded"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
      </div>

      <div className="flex flex-row mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setFilteredData(
              internalPayments.filter(
                (payment) =>
                  payment?.employee?.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  GetDate(payment?.createdAt).includes(e.target.value)
              )
            );
            setCurrentPage(1);
          }}
          className="border p-2 rounded w-full bg-transparent"
        />
        <button
          className="bg-primary text-white p-2 rounded"
          onClick={() => {
            setSearchText("");
            setFilteredData(internalPayments);
            setCurrentPage(1);
          }}
        >
          Reset
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Expense Date
              </th>
              <th scope="col" className="px-4 py-4">
                Payment Date
              </th>
              <th scope="col" className="px-4 py-4">
                Employee Name
              </th>
              <th scope="col" className="px-4 py-4">
                Description
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Type
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Amount Paid
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Screenshot
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {getPageData().map((payment) => (
              <tr className="border-b" key={payment?._id}>
                <td className="px-4 py-3">{GetDate(payment?.createdAt)}</td>
                <td className="px-4 py-3">{GetDate(payment?.payment_date)}</td>
                <td className="px-4 py-3">{payment?.employee?.name}</td>
                <td className="px-4 py-3">{payment?.description}</td>
                <td className="px-4 py-3">{payment?.type}</td>
                <td className="px-4 py-3">{payment?.totalAmount}</td>
                <td className="px-4 py-3">
                  <div
                    className="text-blue-500 cursor-pointer underline"
                    onClick={() => {
                      window.open(payment?.screenshot, "_blank");
                    }}
                  >
                    Screenshot Link
                  </div>
                </td>
                <td className="px-4 py-3 text-center flex items-center justify-center">
                  <div className="cursor-pointer flex items-center gap-4">
                    {showEdit && (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenAddPaymentModal(true);
                          setModalType("edit");
                          setSelectedPayment(payment);
                        }}
                      >
                        <EditIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                      </div>
                    )}
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenViewPaymentModal(true);
                        setSelectedPayment(payment);
                      }}
                    >
                      <EyeIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        DeleteInternalPayment(payment?._id);
                      }}
                    >
                      <DeleteIcon size="w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex flex-row gap-2 justify-center items-center">
          <button
            disabled={currentPage === 1}
            className="bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <div className="flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10">
            {currentPage}
          </div>
          <button
            disabled={currentPage === Math.ceil(filteredData.length / 20)}
            className="bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
