import { Divider } from "antd";
import ManageDepartment from "shared-pages/team/ManageDepartment";
import ServiceProviders from "./service-provider";
import Employees from "./employee";
import BankAccounts from "./accounts";

const EmployeeProviderFinance = () => {
  return (
    <div className="shadow-lg p-10 flex flex-col gap-20">
      <div className="">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Employees</h2>
        </div>
        <Employees />
      </div>

      <div className="">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Departments</h2>
        </div>
        <ManageDepartment isFinanceDepartment={true} />
        <Divider />
      </div>
      <div className="">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Service Providers
          </h2>
        </div>
        <ServiceProviders />
      </div>
      <div className="">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Bank Accounts</h2>
        </div>
        <BankAccounts />
      </div>
    </div>
  );
};

export default EmployeeProviderFinance;
